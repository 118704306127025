import React from "react";

const Covid = () => {
  const covidDec = [
    "infection reported in Kerala, India. On January 27, 2020, a 20 yr old female presented to the Emergency Department in General Hospital, Thrissur, Kerala, with a one-day history of dry cough and sore throat. There was no history of fever, rhinitis or shortness of breath.",

    "India under Complete lockdown for 21 days starting from March 25, 2020",
    "The Prime Minister said, “India is at a stage where our current actions will determine how much we are able to minimize the impact of this disaster. It’s time to exercise caution at every step. You have to remember that Jaan hai toh Jahaan hai. This is the time for patience and discipline. Until the lockdown situation remains, we must keep our resolve, we must keep our promise.” He appealed to the people to beware of any kind of rumors or superstitions circulating during such times. He requested the people not to take any medication without consulting doctors if you are showing any symptoms of this infection. He expressed confidence of every Indian abiding by the instructions of the Government and local authorities during this crucial hour. The Prime Minister concluded though 21 days of lockdown is a long time, but it is equally essential for your safety and that of your family. He expressed confidence that every Indian will not only successfully fight this difficult situation but also emerge victorious.",
  ];
  const covidPoint = [
    {
      txt:"* Commercial and private establishments will be closed down, with exceptions including banks, insurance offices, ATMs, grocery shops, telecommunications, internet, broadcasting and IT services, pharmacies, e-commerce, petrol stations, etc.",
    },
    {
      txt:"* Essential and critical government offices shall continue to operate"
    },
    {
      txt:"* Hospitals and all related medical establishments shall continue to remain functional."
    },
    {
      txt:"* All transport services- air, road, railways- will remain suspended, except for transport of essential goods, fire, law and order and emergency services"
    },
  ];
  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="d-flex justidy-content-center align-items-center w-100">
            <div className="row w-100">
              <div className="col-md-7">
                <div className="dance-text w-100">
                  <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                    <h1
                      className="main-text-vicechairman-title mb-0"
                      style={{ lineHeight: "30px" }}
                    >
                      COVID
                    </h1>
                  </div>

                  {covidDec.map((item, index) => {
                    return (
                      <p className="list-table-one my-4" key={index}>
                        {item}
                      </p>
                    );
                  })}

                  <p className="list-table-one">
                    During the lockdown period, following come into force
                  </p>
                  <ul style={{ listStyle: "disc" }}>
                    {covidPoint.map((item, index) => {
                      return (
                        <li key={index}>
                          <p className="list-table-one mb-1">{item.txt}</p>
                        </li>
                      );
                    })}
                  </ul>                  
                  
                  <p className="list-table-one" style={{fontSize:'12px'}}>Complete guidelines and order issued by Ministry of Home Affairs of India- <a  href="https://pib.gov.in/PressReleseDetail.aspx?PRID=1607997" target="_blank">https://pib.gov.in/PressReleseDetail.aspx?PRID=1607997</a></p>
                  
                  <p className="list-table-one">'Various social organizations, individuals, political leaders have come forward to help Covid-19 patients and their families in whatever manner they can in industrial town Surat.</p>
                    <p className="list-table-one">Along with battling the Covid-19 pandemic, the RAUNIYAR FOUNDATION BHARAT (RFB) has been saddled with a colossal responsibility of feeding thousands of people that include huge population of migrant and jobless workers who are adamant on returning to their native states.</p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row  w-100 h-100 gy-1 overflow-hidden">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="covid-img overflow-hidden">
                          <img src={require('../../../assetes/img/IMG_2206.jpeg')} alt="" className="w-100 object-fit-cover" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="covid-img overflow-hidden">
                          <img src={require('../../../assetes/img/IMG_2204.jpeg')} alt="" className="w-100 object-fit-cover" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="covid-img overflow-hidden">
                          <img src={require('../../../assetes/img/IMG_2208.jpeg')} alt="" className="w-100 object-fit-cover" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="covid-img overflow-hidden">
                          <img src={require('../../../assetes/img/IMG_2205.jpeg')} alt="" className="w-100 object-fit-cover" />
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default Covid;
