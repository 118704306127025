import React, { useEffect, useRef, useState } from "react";
import { Modal as BSModal } from "bootstrap";
import { IoMdClose } from "react-icons/io";
import CreatableSelect from "react-select/creatable";
import ArrayObj from "../../../Constant/arrayObj";
import Pako from "pako";
import { useForm } from "react-hook-form";
import "./cricketleage.css";
import axios from "axios";

const CricketTeamForm = () => {
 
  const initialPlayers = Array.from({ length: 15 }, () => ({
    name: "",
    dob: "",
    age: "",
    position: "",
    arm: "",
    id_proof: "",
    health: "",
    tshirtSize: "",
    pentSize: "",
  }));

  

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const RAZORPAY_API_KEY = process.env.REACT_APP_RAZORPAY_KEY_ID;
  const token = JSON.parse(localStorage.getItem("token"));

  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [players, setPlayers] = useState(initialPlayers);
  const [schoolName, setSchoolName] = useState("");
  const fileInputRef = useRef(null);
  const [teamName, setTeamName] = useState("");
  const [couchFName, setCouchFName] = useState("");
  const [couchLName, setCouchLName] = useState("");
  const [category, setCategory] = useState("");
  const [teamPhoto, setTeamPhoto] = useState("");
  const [add, setAdd] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [teamData, setTeamData] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lastname: couchLName || '',
      pincode: pincode || '',
      city: city || '',
      state: state || '',
      add: add || ''
    }
  });

  const options = ArrayObj.schoolList.map((item) => ({
    value: item.name.toLowerCase(),
    label: item.name,
    other: item.is_other,
  }));

  useEffect(() => {
    const hasHealthIssue = players.filter(player => player.health !== "None").length > 0;

    setIsSubmitDisabled(hasHealthIssue);
  }, [players]);

  const stateList = ArrayObj.indianState;

  useEffect(() => {}, [category, teamPhoto, schoolName, teamPhoto]);
  useEffect(() => {
    setValue("lastname", couchLName);
    setValue("pincode", pincode);
    setValue("city", city);
    setValue("state", state);
    setValue("add", add);
  
    trigger(["lastname", "pincode", "city", "state", "add"]);
  }, [couchLName, city, state, pincode, add, setValue, trigger]);
  

  useEffect(() => {
    if (couchLName) clearErrors("lastname");
    if (pincode) clearErrors("pincode");
    if (city) clearErrors("city");
    if (state) clearErrors("state");
    if (add) clearErrors("add");
  }, [couchLName, pincode, city, state, add, clearErrors]);
  

  useEffect(() => {
    if (showModal && modalRef.current) {
      const bsModal = new window.bootstrap.Modal(modalRef.current, {
        backdrop: "static",
        keyboard: false,   
      });
      bsModal.show(); 

    
      return () => {
        if (bsModal) {
          bsModal.hide();
        }
      };
    }
  }, [showModal]);

  const HandleAddSchool = (name) => {
    if (name) {
      setSchoolName(name.value);
    }
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // covert img in base64

  const uint8ArrayToBase64 = (uint8Array) => {
    const binaryString = Array.from(uint8Array)
      .map((byte) => String.fromCharCode(byte))
      .join("");
    return window.btoa(binaryString);
  };

  const onConvertImg = async (data) => {
    const event = data;
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const tokenConfig = {
          headers: {
            authorization: token,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(
          `${BASE_URL}/api/user/file/upload`,
          formData,
          tokenConfig
        );
        setTeamPhoto(response?.data.data.location);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  };

  const compressBase64 = (base64String) => {
    const uint8Array = base64ToUint8Array(base64String);

    const compressed = Pako.deflate(uint8Array);

    return uint8ArrayToBase64(compressed);
  };

  const handleInputChange = async (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;

    if (field === "dob") {
      const birthDate = new Date(value);
      const userDate = new Date();
      let age = userDate.getFullYear() - birthDate.getFullYear();

      updatedPlayers[index].age = age;
    }

    if (field === "id_proof") {
      let file = value;

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await axios.post(
            `${BASE_URL}/api/user/file/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const updatedPlayers = [...players];
          updatedPlayers[index].id_proof =
            response.data.data.location || response.data.data.key;
          setPlayers(updatedPlayers);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    }

    setPlayers(updatedPlayers);
  };

  const handleSave = (data) => {
    const payload = {
      team_name: teamName,
      fname: couchFName,
      coach_lname: couchLName,
      address: add,
      tcategory: data?.match_category,
      school_name: schoolName,
      team_url: teamPhoto,
      email: email,
      phonenumber: phone,
      city: city,
      state: state,
      emergency_contact: data.emergencyContact,
      dr_contact: data.doctorContact,
      pin_code: data.pinCode,
      players: players.map((player) => ({
        name: player.name,
        age: player.age,
        arm: player.arm,
        dob: player.dob,
        health_his: player.health,
        id_url: player.id_proof,
        position: player.position,
        trouser_size: player.pentSize,
        tshirt_size: player.tshirtSize,
      })),
    };
    console.log("payload", payload);

    axios
      .post(`${BASE_URL}/api/user/team/reg`, payload, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log("response ==>", response.data.data);
        let participentData = response.data.data;
        setTeamData(participentData);
        createPayment(participentData);
        setShowModal(false);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {});
  };

  const createPayment = async (participentData) => {
    console.log("participentData ==>", participentData);

    try {
      const orderUrl = `${BASE_URL}/api/user/team/order/create`;
      const { data } = await axios.post(
        orderUrl,
        {
          amount: participentData.tcategory === "U_19" ? 15000 : 25000,
          team_id: participentData._id,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );

      const options = {
        key: RAZORPAY_API_KEY,
        amount: category == "U_19" ? 15000 : 25000,
        currency: data.currency,
        name: "RAUNIYAR FOUNDATIN BHARAT",
        description: "MARTHON RAGISTRATION CHARITY AMOUNT",
        order_id: data?.data,
        handler: function (response) {
          console.log(response);
          verifyPayment(response);
        },
        prefill: {
          name: `${participentData?.fname} ${participentData?.coach_lname}`,
          email: participentData?.email,
          contact: participentData?.phonenumber,
          participant_id: participentData?._id,
        },
        theme: {
          color: "#e41c3c",
        },
      };

      const paymentObject = new window.Razorpay(options, participentData?._id);
      const response = paymentObject.open();
    } catch (error) {
      console.error("Error while creating order:", error);
    }
  };

  const verifyPayment = (data) => {
    const payload = {
      order_id: "",
      payment_id: "",
      signature: "",
      participant_id: "",
      amount: "",
    };
  };
  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"        
        onClick={handleOpen}
      >
        Register Your Team
      </button>
      {showModal && (
        <div
          className={`modal fade ${showModal ? "show" : ""}`} 
          id="cricketmodal"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          ref={modalRef}
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable position-relative">
            <div className="modal-content cricket_modal">
              <div
                className="close-btn cricket-btn  position-absolute"
                style={{ cursor: "pointer" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                <IoMdClose className="close-modal" />
              </div>

              <div className="w-100">
                <div className="form-section page-section">
                  <div className="form-input-wide" data-type="control_head">
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <div
                        className="form-header-group hasImage header-large w-100"
                        data-imagealign="Top"
                      >
                        <div className="header-logo w-100 overflow-hidden">
                          <img
                            src={require("../../../assetes/img/cricket_tennis.png")}
                            alt="CRICKET TOURNAMENT"
                            className="header-logo-top w-100 object-fit-cover"
                          />
                        </div>
                        <div className="d-flex justify-content-center w-100 my-3">
                          <div
                            className="bpl-logo overflow-hidden"
                            style={{ width: "25%" }}
                          >
                            <img
                              src={require("../../../assetes/ourassosiates/bpl-logo.png")}
                              alt=""
                              className="w-100 object-fit-cover"
                            />
                          </div>
                        </div>
                        <div className="cricket-form-header d-flex flex-column">
                          <div className="header-text d-flex justify-content-center align-items-center w-100">
                            <p
                              id="header_73"
                              className="form-header h1 cricket-header-text text-center"
                              data-component="header"
                            >
                              Bairgania Premier League 2024 <br />
                              First Edition Tennis Ball Cricket Tournament
                            </p>
                          </div>
                          <p
                            id="subHeader_73"
                            className="form-subHeader h5  text-center mt-3"
                          >
                            Registration Form
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container p-4">
                    <div className="row align-items-center w-100 gy-3 gx-2">
                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold mb-2">
                            Team Name
                          </label>
                          <div
                            id="cid_80"
                            className="form-input-wide"
                            data-layout="half"
                          >
                            <input
                              type="text"
                              {...register("teamName", {
                                required: "Enter Your Team Name..",
                              })}
                              placeholder="Enter Team Name"
                              className="race-category"
                              value={teamName}
                              onChange={(e) => setTeamName(e.target.value)}
                            />
                          </div>
                          {errors.teamName && (
                            <p className="invalid">{errors.teamName.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="race-details fw-bold mb-2">
                          Coach Name
                        </label>
                        <div className="row g-2">
                          <div className="col-md-6">
                            <div>
                              <input
                                type="text"
                                {...register("coach_fname", {
                                  required: "Enter first name",
                                })}
                                className="race-category w-100"
                                placeholder="First Name"
                                value={couchFName}
                                onChange={(e) => setCouchFName(e.target.value)}
                              />
                            </div>
                            {errors.coach_fname && (
                              <p className="invalid">
                                {errors.coach_fname.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div>
                              <input
                                type="text"
                                {...register("coach_lname", {
                                  required: "Enter last name",
                                })}
                                className="race-category w-100"
                                placeholder="Last Name"
                                value={couchLName}
                                onChange={(e) => setCouchLName(e.target.value)}
                              />
                              {errors.coach_lname && (
                                <p className="invalid">
                                  {errors.coach_lname.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold my-3">
                            School/College Name
                          </label>
                          <CreatableSelect
                            isClearable
                            options={options}
                            onChange={HandleAddSchool}
                            className="school-select"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                border: "1px solid gray",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 5,
                              colors: {
                                ...theme.colors,
                                primary: "gray",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold my-3">
                            Tournament Category
                          </label>
                          <select
                            {...register("match_category", {
                              required: "Select category",
                            })}
                            className="race-category"
                            defaultValue={category}
                            onClick={(e) => setCategory(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Select Category
                            </option>
                            <option value="U_19">U-19</option>
                            <option value="open">Open</option>
                          </select>
                          {errors.match_category && (
                            <p className="invalid">
                              {errors.match_category.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label className="race-details fw-bold my-3">
                          Player Details
                        </label>
                        <div className="table-responsive">
                          <table className="player_details mb-3">
                            <thead>
                              <tr className="form-matrix-tr form-matrix-header-tr">
                                <th className="form-matrix-th table-header">
                                  No
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_0">Name</label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_1">DOB</label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_1">Age</label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_2">Position</label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_3">Arm</label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_3">ID Proof</label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_3">
                                    Health History
                                  </label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_4">
                                    T-shirt Size
                                  </label>
                                </th>
                                <th
                                  scope="col"
                                  className="form-matrix-headers table-header"
                                >
                                  <label id="label_81_col_4">
                                    Trousers Size
                                  </label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {players.map((player, index) => (
                                <tr
                                  key={index}
                                  className="form-matrix-tr form-matrix-value-tr"
                                >
                                  <th
                                    scope="row"
                                    className="form-matrix-headers table-header"
                                  >
                                    {index + 1}
                                  </th>
                                  <td className="form-matrix-values player-field name-feild-table">
                                    <div>
                                      <input
                                        type="text"
                                        {...register(`players[${index}].name`, {
                                          required: "Enter Player name",
                                        })}
                                        placeholder="Enter Name"
                                        value={player.name}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "name",
                                            e.target.value
                                          )
                                        }
                                        className="race-category"
                                      />
                                      {errors?.players?.[index]?.name && (
                                        <p className="invalid">
                                          {errors.players[index].name.message}
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <input
                                        type="date"
                                        {...register(`players[${index}].dob`, {
                                          required: "Enter Player DOB",
                                        })}
                                        placeholder="Enter Date of birth"
                                        value={player.dob}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "dob",
                                            e.target.value
                                          )
                                        }
                                        className="race-category"
                                      />
                                      {errors?.players?.[index]?.dob && (
                                        <p className="invalid">
                                          {errors.players[index].dob.message}
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <input
                                      type="number"
                                      placeholder="Enter Age"
                                      value={player.age > 0 ? player.age : ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "age",
                                          e.target.value
                                        )
                                      }
                                      className="race-category"
                                      disabled
                                    />
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <input
                                        type="text"
                                        {...register(
                                          `players[${index}].position`,
                                          {
                                            required: "Select Position",
                                          }
                                        )}
                                        placeholder="Enter Position"
                                        value={player.position}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "position",
                                            e.target.value
                                          )
                                        }
                                        className="race-category"
                                      />
                                      {errors?.players?.[index]?.position && (
                                        <p className="invalid">
                                          {
                                            errors.players[index].position
                                              .message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <select
                                        {...register(`players[${index}].arm`, {
                                          required: "Select Arm",
                                        })}
                                        value={player.arm}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "arm",
                                            e.target.value
                                          )
                                        }
                                        className="race-category"
                                      >
                                        <option value="" disabled selected>
                                          Select Arm
                                        </option>
                                        <option value="Left">Left</option>
                                        <option value="Right">Right</option>
                                      </select>
                                      {errors?.players?.[index]?.arm && (
                                        <p className="invalid">
                                          {errors.players[index].arm.message}
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <input
                                        {...register(
                                          `players[${index}].id_proof`,
                                          {
                                            required: "ID proof is required",
                                          }
                                        )}
                                        type="file"
                                        placeholder="Enter id"
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "id_proof",
                                            e.target.files[0]
                                          )
                                        }
                                        className="race-category"
                                      />
                                      {errors?.players?.[index]?.id_proof && (
                                        <p className="invalid">
                                          {
                                            errors.players[index].id_proof
                                              .message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <select
                                        {...register(
                                          `players[${index}].health`,
                                          {
                                            required: "Select health issue",
                                          }
                                        )}
                                        defaultValue={player.health}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "health",
                                            e.target.value
                                          )
                                        }
                                        className="race-category t-shirt"
                                      >
                                        <option value="" disabled selected>
                                          Select Health History
                                        </option>

                                        {ArrayObj.Historus.map((item, idx) => (
                                          <option key={idx} value={item.value}>
                                            {item.value}
                                          </option>
                                        ))}
                                      </select>
                                      {errors?.players?.[index]?.health && (
                                        <p className="invalid">
                                          {errors.players[index].health.message}
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <select
                                        {...register(
                                          `players[${index}].tshirtSize`,
                                          {
                                            required: "Select t-shirt size",
                                          }
                                        )}
                                        defaultValue={player.tshirtSize}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "tshirtSize",
                                            e.target.value
                                          )
                                        }
                                        className="race-category t-shirt"
                                      >
                                        <option value="" disabled selected>
                                          Select T-shirt Size
                                        </option>

                                        {ArrayObj.tshirtSize.map(
                                          (item, idx) => (
                                            <option
                                              key={idx}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {errors?.players?.[index]?.tshirtSize && (
                                        <p className="invalid">
                                          {
                                            errors.players[index].tshirtSize
                                              .message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td className="form-matrix-values player-field">
                                    <div>
                                      <select
                                        {...register(
                                          `players[${index}].pentSize`,
                                          {
                                            required: "Select pent size",
                                          }
                                        )}
                                        defaultValue={player.pentSize}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "pentSize",
                                            e.target.value
                                          )
                                        }
                                        className="race-category"
                                      >
                                        <option value="" disabled>
                                          Select Trouser Size
                                        </option>
                                        {ArrayObj.trouserSize.map(
                                          (item, idx) => (
                                            <option
                                              key={idx}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {errors?.players?.[index]?.pentSize && (
                                        <p className="invalid">
                                          {
                                            errors.players[index].pentSize
                                              .message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold mb-2">
                            Mailing Address
                          </label>
                          <div>
                            <input
                              type="email"
                              placeholder="Enter email"
                              className="race-category"
                              {...register("mailingAddress", {
                                required: "Mailing Address is required",
                              })}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.mailingAddress && (
                              <p className="invalid">
                                {errors.mailingAddress.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold mb-2">
                            Phone Number
                          </label>
                          <div>
                            <input
                              type="tel"
                              placeholder="Enter phone Number"
                              className="race-category"
                              {...register("phoneNumber", {
                                required: "Phone Number is required",
                              })}
                              value={phone}
                              onChange={(e) => setphone(e.target.value)}
                            />
                            {errors.phoneNumber && (
                              <p className="invalid">
                                {errors.phoneNumber.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div>
                          <label
                            htmlFor=""
                            className="race-details fw-bold mb-2"
                          >
                            Address
                          </label>
                          <textarea
                            rows={4}
                            className="race-details fw-semibold mb-2 w-100 text-area-add"
                            {...register("address", {
                              required: "Address is required",
                            })}
                            value={add}
                            onChange={(e) => setAdd(e.target.value)}
                          ></textarea>
                          {errors.address && (
                            <p className="invalid">{errors.address.message}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div>
                          <label className="race-details fw-bold mb-2">
                            City
                          </label>
                          <input
                            type="text"
                            placeholder="Enter City"
                            className="race-category"
                            {...register("city", {
                              required: "City is required",
                            })}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                          {errors.city && (
                            <p className="invalid">{errors.city.message}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div>
                          <label className="race-details fw-bold mb-2">
                            State
                          </label>
                          <select
                            className="race-category"
                            name=""
                            id=""
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option value="select State.." selected disabled>
                              Select State..
                            </option>
                            {stateList.map((item, index) => {
                              return (
                                <>
                                  <option value={item.state} key={item.id}>
                                    {item.state}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div>
                          <label className="race-details fw-bold mb-2">
                            Pin code*
                          </label>
                          <input
                            id="pin_code"
                            type="tel"
                            className="race-category"
                            placeholder="Enter Pin code"
                            inputMode="numeric"
                            {...register("pinCode", {
                              required: "Pin code is required",
                            })}
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                          />
                          {errors.pinCode && (
                            <p className="invalid">{errors.pinCode.message}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold my-3">
                            Emergency Contact
                          </label>
                          <input
                            type="tel"
                            placeholder="Enter Emergency Contact Number"
                            className="race-category"
                            inputMode="numeric"
                            {...register("emergencyContact", {
                              required: "Emergency Contact is required",
                            })}
                          />
                          {errors.emergencyContact && (
                            <p className="invalid">
                              {errors.emergencyContact.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <label className="race-details fw-bold my-3">
                            Doctor's Contact
                          </label>
                          <input
                            type="tel"
                            placeholder="Enter Doctor Contact Number"
                            className="race-category"
                            inputMode="numeric"
                            {...register("doctorContact", {
                              required: "Doctor's Contact is required",
                            })}
                          />
                          {errors.doctorContact && (
                            <p className="invalid">
                              {errors.doctorContact.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div>
                          <div
                            className="jfQuestion-fields"
                            data-wrapper-react="true"
                          >
                            <div
                              className="jfField isFilled"
                              onClick={handleLabelClick}
                            >
                              <div className="jfUpload-wrapper">
                                <div className="jfUpload-files-container">
                                  <label
                                    className="form-label upload-demo form-label-top form-label-auto w-100"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Upload Team Photo
                                  </label>
                                  <input
                                    type="file"
                                   
                                    className="form-upload-multiple"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    onChange={(e) => onConvertImg(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="submit-cricket-form"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className={`submit-cricket-form  ${
                    !isSubmitDisabled ? "submit-form" : "submit-disable"
                  }`}
                  onClick={handleSubmit(handleSave)}
                  disabled={isSubmitDisabled}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CricketTeamForm;
