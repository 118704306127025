import React from "react";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import MediaMenu from "../MediaMenu/MediaMenu";
import "./footer.css";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <section className="main-footer w-100">
      <div className="container">
        <footer className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <img
              src={require("../../assetes/img/new-main-logo.png")}
              alt="Footer Logo"
              className="w-100"
            />
            <p className="text-body-secondary">&copy; 2024</p>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <h5>FOCUS AREAS</h5>
            <ul className="nav flex-column mt-3">
              <li className="nav-item">
                <Link
                  to={"/"}
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/termsandcondition"}
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Terms and Condition
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/privatepolicy"}
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/refundandcancelationpolicy"}
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Refunds/Cancellations*
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/contactus"}
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Donation
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link p-0 text-body-secondary fw-semibold"
                >
                  Login
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <h5>CONTACT</h5>
            <ul className="nav flex-column mt-3">
              <li className="nav-item d-flex align-items-center gap-2">
                <FaEnvelope className="fs-5 me-2" />
                <a
                  href="mailto:info@rauniyarfoundationbharat.org"
                  className="text-decoration-none text-body-secondary fw-semibold"
                >
                  info@rauniyarfoundationbharat.org
                </a>
              </li>
              <li className="nav-item d-flex align-items-center gap-2">
                <FaEnvelope className="fs-5 me-2" />

                <a
                  href="mailto:rauniyarfoundation@gmail.com"
                  className="text-decoration-none text-body-secondary fw-semibold"
                >
                  rauniyarfoundation@gmail.com
                </a>
              </li>
              <li className="nav-item d-flex align-items-center gap-2 mt-2">
                <IoMdCall className="fs-5 me-1" />
                <a
                  href="+918155067100"
                  className="footer-call-txt text-decoration-none text-body-secondary fw-semibold"
                >
                  +91 8155067100
                </a>{" "}
                /
                <a
                  href="+918155067102"
                  className="footer-call-txt text-decoration-none text-body-secondary fw-semibold"
                >
                  +91 8155067102
                </a>
              </li>
              <li className="nav-item d-flex align-items-start gap-2 mt-2">
                <FaLocationDot className="location-icon me-1" />
                <p className="footer-address fw-semibold text-body-secondary">
                  418,Fourth Floor,Times Corner,Near Ambrosia Business Hub, VIP
                  Road,Vesu,Surat-395 007 Gujarat, Bharat
                </p>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <h5 className="mb-3">Media</h5>
            <MediaMenu />
          </div>
        </footer>
      </div>
    </section>
  );
};

export default Footer;
