import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Photography = () => {
  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="row w-100 gx-3 gy-4">
            <div className="col-md-7">
              <div className="merathon-detail">
                <div className="register-dec">
                  <Tabs
                    defaultActiveKey="Regular"
                    id="uncontrolled-tab-example"
                    className="mb-3 d-none"
                  >
                    <Tab eventKey="Regular" className="tab-title">
                      <p
                        className={`regular-dec mahostav-dec main-text-chairman list-table-one`}
                      >
                        <p>
                          <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                            <h1 className="main-text-vicechairman-title mb-0">
                              Rauniyar Foundation Bharat(RFB) Photography Awards
                              2024
                            </h1>
                          </div>
                          Sharing your passion with the world is an important
                          part of the creative process. If you’re considering
                          submitting your photos to a photo contest, but you
                          can’t decide which contest suits you best, we have
                          something for you! We have prepared this guide to the
                          best photo contests in 2024 based on many years of
                          experience with running a platform providing best
                          photo contest news in the world. Participating in
                          international, prestigious photo contest will bring
                          you recognition and distinction, as well as monetary
                          and physical prizes. Apply today and increase your
                          chances of success in the photography and art
                          industry! <br />
                          <br />
                          <FaArrowRightLong className="me-2" />
                          <span className="fw-semibold">
                            Registration Deadline: Our event is delay, New dates for all programs will be announced soon.Stay connected.
                          </span>
                          <br />
                          <br />
                          <FaArrowRightLong className="me-2" />
                          <span className="fw-semibold">
                            Prizes: Trophy/Medal/Certificate
                          </span>
                          <br />
                          <br />
                          <FaArrowRightLong className="me-2" />
                          <span className="fw-semibold">
                            Entry Fee:{" "}
                            <Link
                              to={"/bairganiaMahotsav"}
                              className="text-mahostav"
                            >
                              Entry Link
                            </Link>{" "}
                            <br />
                          </span>
                          <br />
                          Rauniyar Foundation Bharat(RFB) Photography Awards is
                          one of the most valued photo contests in the world. A
                          high monetary prize, physical prizes, professional,
                          amateur, students, and young photographers categories,
                          as well as lack of an entry fee make it one of the
                          most popular ones as well. The competition here is
                          extreme, and a distinction will help you with further
                          success and fame in the world of art.
                        </p>
                      </p>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex  align-items-end w-100 h-100 overflow-hidden">
                <div className="mahostav-video p-3 w-100">
                  <div className="video-mahostav overflow-hidden rounded-2">
                    <video
                      className="w-100 h-100 object-fit-cover"
                      poster={require("../../../assetes/img/BairganiaPNG.png")}
                      controls
                    >
                      <source
                        src={require("../../../assetes/video/mahostav.mov")}
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Photography;
