import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Dance = () => {
  const danceLevels = [
    {
      header: "RECREATIONAL",
      txt: "This level is for dancers who only take a class or are in an instruction situation 3 hours or less a week. This level is for Beginning dancers and/or those with little or no experience in a competitive environment. Recreational Dancers are limited to 1 Solo. Just because a dancer is a beginner or has limited experience does not mean they have to be entered at this level; if they have the hours and the skill, give them the push. Dancers with TWO or more solos must enter at the Intermediate or Advanced Level.",
    },
    {
      header: "INTERMEDIATE",
      txt: "This level is for recreational students, those who only take class or practice in an instructional situation for less than 5 hours per week. This includes all solo, duo, duet, and trio times, as well as weekly practice time in addition to classes.",
    },
    {
      header: "ADVANCED",
      txt: "This level is for dancers who take a class or are in an instructional situation for more than 5 hours per week and is open to any and all participants. If a dancer’s skill level is truly advanced, they need to be entered at the Advanced level regardless of the number of hours taken per week.",
    },
  ];
  const danceRules = [
    "Soloists may only perform one solo and be entered in the RECREATIONAL Level. If performing multiple solos, then a dancer must enter in the Intermediate or Advanced Level.",
    "Duet/Trios need to enter at the highest level of dancer. EXAMPLE: A trio has two Intermediate Dancers and one Advanced Solo Dancer; it needs to be entered at the Advanced Level. The same goes for a Duet/Trio; if one dancer is Advanced for Solo, it needs to be entered at the Advanced Level.",
    "If 50% of a group routine is a higher level, then the group needs to be entered at a higher level.",
  ];
  const performanceCategories = [
    {
      txt: "Solo (1 Performer)",
    },
    {
      txt: "Duet/Trio (2-3 Performers)",
    },
    {
      txt: "Small Group (4-9 Performers)",
    },
    {
      txt: "Large Group (10-19 Performers)",
    },
    {
      txt: "Super Group (20+ Performers)",
    },
    {
      txt: "Production (15+ Performers and have a theme or tell a story) **Production has an extended time limit**",
    },
  ];
  const ageDivisions = [
    {
      txt: "Tiny Division (6 & under) **No Levels**",
    },
    {
      txt: "Mini Division (7-8)",
    },
    {
      txt: "Petite Division (9-10)",
    },
    {
      txt: "Junior Division (11-12)",
    },
    {
      txt: "Teen Division (13-14)",
    },
    {
      txt: "Senior Division (15-19)",
    },
    {
      txt: "If entries merit then Senior SOLOS will break into (15-16) & (17-19)",
    },
    {
      txt: "Elite Division (20 +) **No Levels**",
    },
  ];
  const competitionLevels = [
    {
      txt: "Power Diamond (291-300) Judge (97-99)",
    },
    {
      txt: "Power Platinum (282-290) Judge (94-96)",
    },
    {
      txt: "Platinum (270-281) Judge (90-93)",
    },
    {
      txt: "Elite Gold (261-269) Judge (87-89)",
    },
    {
      txt: "High Gold (252-260) Judge (84-86)",
    },
    {
      txt: "Gold (240-251) Judge (80-83)",
    },
  ];
  const danceScore = [
    {
      header: "Overall and Top Score Awards",
      txt: [
        "Overall Recognition will be awarded in Solo, Duet/Trio, Small, Large, and Super Group, and Production Divisions, within each overall age division and competitive level. The 1st Place overall scoring group routine in each overall age division and competitive level will be awarded a Top Score DDRT Award; divisions will go out to a Top 10 for Top Score Recognition.",
        "Other awards given will include The Crowd Pleaser Award, The Choreography Award, The Entertainment Award, The Innovation Award, and the DDRT Studio of Excellence Award.",
        "Bravo Awards are selected by the judges for outstanding performances, creative concepts, and awesome potential.",
        "NEW in 2024...Judges may choose Routines to be Awarded the 'High Voltage Award' for Powerful, Innovative, Inspiring, or Captivating Routines! This will be awarded on stage immediately following the routine's performance.  The announcement will be accompanied by Lights & Sounds that signal that a High Voltage Power Performance is being Awarded.",
        "Champion Awards for Top Scoring Groups and Productions will be awarded. These could be by Age Division or Level based on competition size and number of entries in levels.",
      ],
    },
    {
      header: "General Information",
      txt: [
        "MUSIC needs to be uploaded or submitted in advance of the competition using our online system. All music should be recorded at a proper speed. FOR EMERGENCY, it is advisable to have a duplicate Flash Drive available",
        "TIME LIMITS: Solos, Duet/Trios 3:00 min, Groups 4:00 min, and Productions 10:00 min (Time limit includes entrance/exit and prop setup); If extended time is needed for entries, contact the office for procedure.",
        "NUMBER OF ENTRIES: Entries will be on a first come, first served basis and will be limited to the amount of performance time available. DDRT reserves the right to add additional competition days or move the competition location due to unforeseen circumstances. DDRT also bears the right to refuse entries at their discretion.",
        "VIDEOTAPING AND PHOTOGRAPHY IS PROHIBITED at DDRT and RFB Family Competitions. This is for the protection of all performers and studios. We give FREE PHOTOS AND VIDEO of every routine for performers and studios at regional competitions and national championships.",
        "Use our ONLINE Entry System to submit routines. A schedule email will be sent via email 10-12 days before the regional competition. Marathon Unpaid entries will not be scheduled. A general performance schedule will be posted on the website a week prior to the competition. If entry Marathon fees are not paid, we will not allow performers to participate in the competition. ALL ENTRIES SHOULD BE PAID BY CERTIFIED FUNDS or CREDIT CARD. Double-check all marathon entries before submitting them, as a “change fee” will be applied to your account if changes are needed once the schedule is created.",
        "ALL FEES ARE NONREFUNDABLE FOR ANY REASON… Credits can be issued for future events based on the individual situation. Studio directors must facilitate this communication; our office will not speak with performers' parents/guardians.",
        "Routines awarded an Elite Gold Award or higher qualify to compete at one of our DDRT-Rauniyar Foundation Bharat (RFB)- Bairagnia Mahotsav 2nd Edition 2024 Championships Finals.",
        "Photogenic Entries: Entries must bring to the competition a recent 5 x 7 or 8 x 10 in black and white or color to the competition. On the back of the photo, list the name, age, and studio. Photos will be returned to the merchandise table at the conclusion of the age division. DDRT is not responsible for any damages to the photograph submitted. You must enter photogenic on the day of the competition. You do not have to be a registered competitor to enter the photogenic category, but you must be affiliated with a registered studio. DO NOT SEND PICTURES OR MONEY FOR PHOTOGENIC ENTRIES - you must enter at the competition. Each competition will have a limited number of photogenic entries. Photos will receive an Adjudicated Award and are eligible for Top Score Awards. Top Scoring Photogenic Awards will be presented at Group Awards during regional competitions.",
        "Absolutely NO forms of liquids (including water), gels, aerosols, glitter, confetti, etc., which may affect the stage surface, may be used during a routine. All props must be in place within the given time limit of the routine unless certain circumstances warrant reasonable additional time due to the stage location and conditions.",
        "All routines must compete on the day and time assigned. Entries that do not compete on their assigned day and time will be adjudicated but will not be eligible for top score awards.",
        "Any change in competition days and/or times is at DDRT's sole discretion and will be determined by the amount of time available at each regional competition.",
        "A studio schedule will be emailed 8-10 days before the competition date if paid in full. DDRT does not have a general format we follow at our regional competitions. Every regional schedule is based solely on the breakdown of entries by solos, duets/trios, groups, and productions for that particular event. We do not have this breakdown in numbers until all of the entries have been processed, and at that time, we set up a schedule that will best accommodate the participants in that competition. We would rather go later on a Saturday, so it is not a late night on a Sunday when participants have school or work the next day. This would not always be possible if we had a general format.",
        "DDRT reserves the right to extend or condense the number of days of competition or move the competition location due to unforeseen circumstances.",
        "The dance machine rules will be evaluated and enforced individually based on what is best for all parties involved. We pride ourselves on being FAIR!!!",
        "Maxx Opportunities, Inc. DDRT/The RFB Family of Competitions & Conventions and the hosting facility are not responsible for personal injury or property loss. A panel of well-qualified judges will judge contestants, and all judges' decisions will be accepted as final.",
      ],
    },
  ];
  const danceStyles = [
    {
      txt: "Acrobatic: A routine containing acrobatic technique with dance steps to connect movement.",
    },
    {
      txt: "Ballet: A routine containing ballet technique and steps. No acro/gymnastic tricks.",
    },
    {
      txt: "Baton: Use of baton technique, while using a baton, with dance steps to connect movement.",
    },
    {
      txt: "Character: A dance routine of any style that portrays a recognizable character.",
    },
    {
      txt: "Cheerleading: Routine can include chants, tumble passes, lifts, stunts, pom pons, and dance.",
    },
    {
      txt: "Clogging: A routine using clogging technique and steps. We also offer clogging line, clogging moving line, and clogging exhibition for clogging group routines. These are available in our online entry system.",
    },
    {
      txt: "Contemporary: A blend of modern and lyrical styles with an edge.",
    },
    {
      txt: "Ethnic: A routine using movement native to a particular culture or nationality.",
    },
    {
      txt: "Hip-Hop: A routine consisting of pop-locking, funk, or street style dance.",
    },
    {
      txt: "Jazz: A routine using jazz technique and steps.",
    },
    {
      txt: "Lyrical: Routine must contain interpretation of music, use of extension, control, and balance.",
    },
    {
      txt: "Modern: The interpretation of an idea with the use of isolations, contractions, and a demonstration of a feel for music.",
    },
    {
      txt: "Musical Theatre: A routine portraying a Broadway or film character or theme, vocalization and lip-sync are allowed.",
    },
    {
      txt: "Open: A combination of dance styles, and any acrobatics/gymnastic tricks are allowed.",
    },
    {
      txt: "Pointe: A routine containing ballet technique and steps while wearing Pointe shoes. No acrobatics/gymnastics tricks.",
    },
    {
      txt: "Pom-Pom: A routine using pom-poms with dance movement.",
    },
    {
      txt: "Song and Dance: A routine using both vocal singing and dance.",
    },
    {
      txt: "Tap: A routine containing tap technique and steps. Try to avoid tap sounds in music.",
    },
  ];
  const competitionInfo = [
    {
      question: "What are the entry fees for competitions?",
      answer:
        "Studio owners can contact our office for entry fees. We are very competitive in the industry.",
    },
    {
      question: "How do you register for the competitions?",
      answer:
        "Only studio owners and teachers may register for competitions. We have an online entry system that is easy to use and helps to make the registration process organized and efficient. Only studio owners may contact our office for entry instructions. All accounts must be verified that they are a studio owner/faculty member.",
    },
    {
      question: "Is videotaping/taking photographs allowed at competitions?",
      answer:
        "For the safety of your dancers, we DO NOT allow photos or videos at our competitions. However, every dancer receives FREE access to photos and videos of every routine they perform at the competition.",
    },
    {
      question: "Do you livestream your competitions?",
      answer:
        "For the safety of the dancers and the protection of studio choreography/concepts, we choose not to livestream. We do provide free Photo & Video to all participants and do our best to get them UPLOADED as quickly as possible to share with family & friends.",
    },
    {
      question: "What is the age cut-off for age categories?",
      answer:
        "All ages for competitions are as of November 15th, 2024. Please double-check all birth dates in the online system so everyone is entered correctly. Incorrect birth dates could cause families to be unable to access free photo and video.",
    },
    {
      question:
        "How many acrobatics/gymnastics tricks can we have in a routine?",
      answer:
        "Up to five before it has to be entered in open or acrobatics. Character, cheerleading, and musical theater, there is no limit to the number of tricks. Ballet and pointe no acrobatics/gymnastics tricks! We do not apply point deductions if a category change is made.",
    },
    {
      question: "Do you have a limit to how many solos a dancer can perform?",
      answer:
        "We do not limit the number of solos a student can perform in the Intermediate and Advanced Levels. If a soloist is entered in the Recreational Level, they are limited to one solo; if they have more than one solo, they will need to enter at the Intermediate or Advanced Level. We do reserve the right to limit the number of solos by an individual that can place in Top Score Rankings. *If a dancer has more than 2 solos, they need to be entered in the ADVANCED Level*",
    },
    {
      question: "Do you have a Regional Titles Competition? How do you enter?",
      answer:
        "All soloists are eligible for Regional Titles. Any soloist that competes at the Regional Competition may enter the Bairgania’s Mr & Miss Dance Titles Competition to be held 2025 in Bairgania. At the DDRT Title Competition, soloists also compete in Interview, Class, Self-Introduction, and their Solo Routine. They have a Thursday Night Social Event, have a Red Carpet Party, and the Awards Gala.",
    },
    {
      question: "Do you accept Independent Entries?",
      answer:
        "We DO NOT accept independent entries for competitions; you must be registered with a studio or school in order to compete at DDRT. We do allow independent registration for our conventions; you can contact our office if interested in attending a convention as an independent; studio permission is still encouraged.",
    },
    {
      question: "How do you schedule competitions?",
      answer:
        "DDRT does not have a general scheduling format, which we follow at our regional competitions. Every regional schedule is set up based solely on the breakdown of entries by solos, duet/trios, groups, and productions for that particular event. We do not have this breakdown in numbers until all of the entries have been processed, and at that time, we set up a schedule which will best accommodate the participants at that regional. We would rather go later on a Saturday, so it is not a late night on a Sunday when participants have school or work on the next day. This would not always be possible if we had a general format. We do Keep Group Routines in Saturday & Sunday for Regional Competitions.",
    },
    {
      question: "When will we get a schedule for the competition?",
      answer:
        "We will send out a schedule via email to studio directors 10-12 days before the event, we will send it sooner if it is ready. We post the General Schedule Online about 7 days before the competition.",
    },
    {
      question: "How are photogenic entries submitted?",
      answer:
        "Photogenic Entries must be brought to the competition as a recent 5 x 7 or 8 x 10 in black and white or color to the competition. Please write the name, age, and studio on the back of the photo. Photos will be returned at the competition, but photogenic contestants are responsible for retaining their photos on that day. DDRT is not responsible for any damages to the photograph submitted. You must enter photogenic on the day of the competition. You do not have to be a registered competitor to enter the photogenic category, but you must be affiliated with a registered studio. DO NOT SEND PICTURES OR MONEY FOR PHOTOGENIC ENTRIES - you must enter at the competition. Each photogenic entry will be awarded an adjudication award. Top Score Photogenic Awards will be based on the number of entries submitted. All Photogenic Awards will be presented with Group Awards for the corresponding age division.",
    },
    {
      question: "How are routine critiques given to studios?",
      answer:
        "Each judge will give an audio/video critique. Scores and audio/video critiques will be uploaded to studio accounts by the Wednesday after a competition.",
    },
    {
      question: "What awards do you give?",
    },
    {
      question: "Adjudicated Rating System",
      answer:
        "**Each judge awards a point value to each routine… Then, the three-point values are added together to determine the rating.",
    },
    {
      question: "Adjudicated Rating Awards",
      answer:
        "Power Diamond (291-300) Power Platinum (282-290), Platinum (270-281), Elite Gold (261-269), High Gold (252-260), and Gold (251 or less)",
    },
    {
      question: "Top Score Overall Awards",
      answer:
        "Age divisions are TINY 6 and under, MINI 7-8, PETITE 9-10, JUNIOR 11-12, TEEN 13-14, SENIOR 15-19, ELITE 20 and up.",
    },
  ];
  const awardsInfo = [
    {
      txt: "Each competitive level (RECREATIONAL, INTERMEDIATE, and ADVANCED), all receive Top Score awards for Solo, Duet/Trio, Small Group, Large Group, Super Group, and Production within the Competitive Level and Age Division. If there are less than 3 entries in an age division/level then they may be combined.",
    },
    {
      txt: "BRAVO Awards are presented to outstanding performances and innovative/creative choreography. Bravo Awards are given for Solos, Duet/Trios, Groups, and Productions. Bravo Awards are selected by Judges and are not directly related to Adjudication, Rating Awarded, or Top Score Placement.",
    },
    {
      txt: "Entertainment, Crowd Pleaser, and Choreography Awards are presented in the Group/Production Divisions. These awards can come from any Competitive Level and are not directly related to the Adjudication, Rating Awarded, or Top Score Placement.",
    },
    {
      txt: "At the National Finals, all of the above awards are presented with the opportunity to be the National Champions, and groups have the chance to qualify for the Clash of the Champions Dance-Off, where the Super Grand National Champions are awarded. The National Dance-Off immediately follows the Age Division at nationals… No routines come back for an additional day.",
    },
  ];

  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="d-flex justidy-content-center align-items-center w-100">
            <div className="row w-100">
              <div className="col-md-7">
                <div className="dance-text w-100">
                  <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                    <h1
                      className="main-text-vicechairman-title mb-0"
                      style={{ lineHeight: "30px" }}
                    >
                      Rauniyar Foundation Bharat(RFB) <br /> Dance Dance
                      Revolution Tournament -2024
                    </h1>
                  </div>
                  <div className="my-3">
                    <div className="half-merathone-list-title">
                      <p className="list-table-one">Rules and Guidelines</p>
                    </div>
                  </div>
                  <p className="list-table-one">
                    At DDRT, we encourage studio spirit and fellowship among
                    studios. If you come with an attitude of we are “here to
                    win” or “here to beat others,” then this is not the
                    competition for your studio/school. We promote an
                    environment of positive energy and growth of the students.
                  </p>
                  <p className="list-table-one">
                    <span className="fw-semibold">DDRT philosophy:</span>{" "}
                    Competition is not about “beating out others” but improving
                    yourselves, appreciating what you have learned and
                    acknowledging others' accomplishments. Everything else is
                    icing on the cake!
                  </p>
                  <p className="list-table-one">
                    Studio & Schools directors may contact our office for entry
                    fees and online registration information.{" "}
                  </p>
                  <p className="list-table-one">
                    Every dancer and studio receives FREE PHOTO AND VIDEO of all
                    routines they perform at Regional Competitions, National
                    Championships, and the National Titles Competition!!!
                  </p>
                  <p className="list-table-one">
                    DDRT is part of the Rauniyar Foundation Bharat (RFB)-
                    Bairagnia Mahotsav 2nd Edition 2024 Family of Competitions &
                    Conventions.
                  </p>
                  <p className="list-table-one">
                    DDRT as a part of the RFB Family of Competitions &
                    Conventions expects all studios & Schools that participate
                    in events to maintain a high moral standard, keep a positive
                    attitude, and encouraging nature. DDRT is designed to be a
                    learning experience and an opportunity for everyone to be
                    recognized for their accomplishments. We offer twenty
                    categories, three competition levels, six overall age
                    divisions, a titles division, and many fun and free
                    divisions to show your studio’s spirit. DDRT promotes a
                    family-geared environment. Routines that are seductive,
                    contain explicit lyrics, and suggestive material could
                    result in substantial point deductions. We expect all
                    entries to be appropriate for family viewing. We also expect
                    all parents, teachers, and dancers to use good moral
                    character and values at the competition. We encourage good
                    sportsmanship!!! Not following these moral guidelines could
                    cause a studio to be asked not to return to our events.
                  </p>
                  <span className="fw-semibold d-flex align-items-center gap-3">
                    <FaArrowRightLong className="me-2 my-4" />
                    Entry Fee:{" "}
                    <Link to={"/bairganiaMahotsav"} className="text-mahostav">
                      Entry Link
                    </Link>{" "}
                    <br />
                  </span>
                  {danceLevels.map((item, index) => {
                    return (
                      <>
                        <div key={index} className="d-flex">
                          <span>
                            <FaArrowRightLong className="me-2" />
                          </span>
                          <p className="list-table-one">
                            <span className="fw-semibold">
                              {item.header}:&nbsp;
                            </span>
                            {item.txt}
                          </p>
                        </div>
                      </>
                    );
                  })}
                  <div className="d-flex  mb-3">
                    <span>
                      <FaArrowRightLong className="me-2" />
                    </span>
                    <p className="list-table-one mb-0">
                      Level hours are based on all classes taken, not by each
                      style of dance taken. So if a student takes Indian
                      classical,ballet, tap, jazz, lyrical, hip hop, and
                      acrobatics, all of these classes should be added to
                      determine levels. If additional ballet/technique classes
                      are taken or weekly solo/duet/trio classes are taken,
                      these should also be included in the tally of hours.
                    </p>
                  </div>
                  <p className="list-table-one">
                    You will compete in the division entered. Please do not ask
                    us to change it on the day of competition!
                  </p>
                  <p className="list-table-one">
                    Because NO competition can possibly know how many hours a
                    child dances or for how many years they have danced,
                    teachers are left to their own sound judgment when entering
                    routines. The judges may elevate routines that are obviously
                    under-placed. All decisions are FINAL.
                  </p>
                  <div className="my-3">
                    <div className="half-merathone-list-title">
                      <p className="list-table-one">
                        Three Important Rules Applying to Level Placement
                      </p>
                    </div>
                  </div>
                  {danceRules.map((item, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <span>
                          <FaArrowRightLong className="me-2" />
                        </span>
                        <p className="list-table-one">{item}</p>
                      </div>
                    );
                  })}

                  <div className="half-merathone-list-title">
                    <p className="list-table-one">Divisions</p>
                  </div>
                  <ul style={{ listStyle: "disc" }}>
                    {performanceCategories.map((item, index) => {
                      return (
                        <li key={index}>
                          <p className="list-table-one mb-1">{item.txt}</p>
                        </li>
                      );
                    })}
                  </ul>
                  <p className="list-table-one">
                    Our Policy on Teachers/Professionals Competing: This is a
                    sensitive issue; however, this is our policy. WE OFFER AN
                    ELITE DIVISION… Any routine solo, duet/trio, or group with a
                    teacher in the routine must compete in the ELITE DIVISION;
                    this is regardless of average age. ELITE DIVISION will have
                    its own top score awards separate from other divisions but
                    will not have levels. Teachers may be in production numbers
                    with no restrictions on age division. We will treat each
                    situation individually, and our decision will be final. When
                    in doubt, please ask! By DDRT Productions policy, a
                    Teacher/Professional is anyone who has graduated high school
                    and is paid to teach/choreograph classes/solos/duet/trios
                    over two hours per week.
                  </p>
                  <p className="list-table-one">
                    <span className="fw-semibold">Regional Title:</span> All
                    Soloists are eligible for Regional Title unless they hold a
                    National Title in the RFB Family of Competitions &
                    Conventions. There is no FEE for the regional title. A
                    regional title winner is selected in each overall age
                    division. Any soloist scoring a Platinum Adjudication or
                    higher at a regional competition may enter the Titles
                    Competition held in 2023 in Bairgania.
                  </p>
                  <div className="half-merathone-list-title">
                    <p className="list-table-one">Overall Age Divisions</p>
                  </div>
                  <ul style={{ listStyle: "disc" }}>
                    {ageDivisions.map((item, index) => {
                      return (
                        <li key={index}>
                          <p className="list-table-one mb-1">{item.txt}</p>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="d-flex">
                    <span>
                      <FaArrowRightLong className="me-2" />
                    </span>
                    <p className="list-table-one">
                    Our event is delay, New dates for all programs will be announced <soon className="span fw-semibold">Stay connected</soon>.
                    </p>
                  </div>
                  <p className="list-table-one">
                    Teachers may contact the office for regional entry
                    registration. We are very competitive in the industry with
                    our entry registration.{" "}
                  </p>
                  <div className="half-merathone-list-title">
                    <p className="list-table-one">Adjudicated Awards</p>
                  </div>
                  <p className="list-table-one">
                    Judges' scores are combined for a Total Score, determining
                    the Adjudicated Rating Award. Below are the Rating Values
                    and each judge's Score Range for the adjudication level.{" "}
                  </p>
                  {competitionLevels.map((item, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <span>
                          <FaArrowRightLong className="me-2" />
                        </span>
                        <p className="list-table-one mb-1">{item.txt}</p>
                      </div>
                    );
                  })}
                  <div className="half-merathone-list-title my-3">
                    <p className="list-table-one">
                      **All Competitive Levels are scored on the same
                      Adjudication Scale**
                    </p>
                  </div>
                  <p className="list-table-one">
                    Each soloist and member of a Duo/Duet/Trio will receive
                    their own adjudicated award. Each dancer that participates
                    in group routines will be awarded a participation lapel pin
                    for each routine. The Teachers/Directors will be awarded a
                    DDRT Adjudicated Award for the studio. Lapel Pins will be
                    bagged by group and given out on stage to groups.
                  </p>
                  <p className="list-table-one">
                    Each routine will receive a digital score sheet that will be
                    uploaded to studio accounts and an Audio/Video Critique for
                    each entry. Scores and Critiques can be accessed on your
                    studio Dashboard in the registration system. We do ask that
                    you allow 24-48 hours after a regional competition for
                    everything to upload and publish to your account.
                  </p>
                  {danceScore.map((item, index) => {
                    return (
                      <>
                        <div
                          className="half-merathone-list-title my-2"
                          key={index}
                        >
                          <p className="list-table-one">{item.header}</p>
                        </div>
                        {item.txt.map((item, index) => {
                          return (
                            <p className="list-table-one" key={index}>
                              {item.includes("TIME LIMITS") ||
                              item.includes("NUMBER OF ENTRIES") ? (
                                <strong>{item}</strong>
                              ) : (
                                item
                              )}
                            </p>
                          );
                        })}
                      </>
                    );
                  })}
                  <div className="half-merathone-list-title my-3">
                    <p className="list-table-one">
                      Dance Machine Productions Categories
                    </p>
                  </div>
                  <p className="list-table-one">
                    Categories where acrobatic/gymnastics tricks are not allowed
                    are specified; in other categories, please keep tricks to
                    less than five or enter in open. Questions? Please ask us.
                    No limit to tricks in character, cheerleading, musical
                    theater, or pom-pom categories.
                  </p>
                  <ul style={{ listStyle: "disc" }}>
                    {danceStyles.map((item, index) => {
                      return (
                        <li key={index}>
                          <p className="list-table-one mb-1">{item.txt}</p>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="half-merathone-list-title my-3">
                    <p className="list-table-one">FAQ’s</p>
                  </div>
                  {competitionInfo.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex align-items-center">
                          <FaArrowRightLong className="me-2" />
                          <span className="que fw-semibold my-2">
                            {item.question}
                          </span>
                        </div>
                        <p className="list-table-one">{item.answer}</p>
                      </div>
                    );
                  })}
                  <div className="half-merathone-list-title my-3">
                    <p className="list-table-one">
                      **Senior Solos will be broken into (15-16) and (17-19)
                      when the number of entries merits a split**
                    </p>
                  </div>
                  {awardsInfo.map((item, index) => {
                    return (
                      <p className="list-table-one" key={index}>
                        {item.txt}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-5">
                <div className="d-flex  align-items-start w-100 h-100 overflow-hidden">
                  <div className="mahostav-video p-3 w-100 h-100">
                    <div className="video-mahostav overflow-hidden rounded-2">
                      <video
                        className="w-100 h-100 object-fit-cover"
                        poster={require("../../../assetes/img/BairganiaPNG.png")}
                        controls
                      >
                        <source
                          src={require("../../../assetes/video/dance.mov")}
                        />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dance;
