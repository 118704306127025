const ArrayObj = {
  // marathon
  raceType: [
    {
      id: 1,
      fRace: "21 Km Run (₹ 599)",
      label: "21 Km Run (₹ 599)",
      price: 599,
    },
    {
      id: 2,
      fRace: "10 Km Run (₹ 599)",
      label: "10 Km Run (₹ 599)",
      price: 599,
    },
    {
      id: 3,
      fRace: "5 Km Run (₹ 599)",
      label: "5 Km Run (₹ 599)",
      price: 599,
    },
    {
      id: 4,
      fRace: "4 Km Run (₹ 499)",
      label: "4 Km Run (₹ 499)",
      price: 499,
    },
  ],
  personalDetails: [
    { id: 1, value: "Aadhar_Card", label: "Aadhar Card" },
    { id: 2, value: "PAN", label: "PAN" },
    { id: 3, value: "Passport", label: "Passport" },
    { id: 4, value: "Voter_ID", label: "Voter ID" },
    { id: 5, value: "Driving_License", label: "Driving License" },
    { id: 6, value: "Selfie_View", label: "Selfie View" },
    { id: 7, value: "i_Card", label: "I-Card" },
  ],

  tshirtSize: [
    { id: 1, value: "Kids small (32)", label: "Kids small (32)" },
    { id: 2, value: "Kids medium (34)", label: "Kids medium (34)" },
    { id: 3, value: "X-small (36)", label: "X-small (36)" },
    { id: 4, value: "S (38)", label: "S (38)" },
    { id: 5, value: "M (40)", label: "M (40)" },
    { id: 6, value: "L (42)", label: "L (42)" },
    { id: 7, value: "XL (44)", label: "XL (44)" },
    { id: 8, value: "XXL (46)", label: "XXL (46)" },
  ],

  bloodGroup: [
    { id: 1, value: "O+", label: "O+" },
    { id: 2, value: "O-", label: "O-" },
    { id: 3, value: "A+", label: "A+" },
    { id: 4, value: "A-", label: "A-" },
    { id: 5, value: "B+", label: "B+" },
    { id: 6, value: "B-", label: "B-" },
    { id: 7, value: "AB+", label: "AB+" },
    { id: 8, value: "AB-", label: "AB-" },
    { id: 9, value: "Don't Know", label: "Don't Know" },
  ],

  Historus: [
    { id: 1, value: "Allergies", label: "Allergies" },
    { id: 2, value: "Heart Disease", label: "Heart Disease" },
    { id: 3, value: "Hypertension", label: "Hypertension" },
    { id: 4, value: "Diabetes", label: "Diabetes" },
    { id: 5, value: "Seizures", label: "Seizures" },
    { id: 6, value: "Asthma", label: "Asthma" },
    { id: 7, value: "None", label: "None" },
    { id: 8, value: "Other", label: "Other" },
  ],

  relation: [
    { id: 1, value: "Father", label: "Father" },
    { id: 2, value: "Mother", label: "Mother" },
    { id: 3, value: "Sister", label: "Sister" },
    { id: 4, value: "Brother", label: "Brother" },
    { id: 5, value: "Spouse", label: "Spouse" },
    { id: 6, value: "Other", label: "Other " },
  ],

  marathonRegisterData: [
    {
      id: 1,
      mtype: "Marathon",
      mFees: "N/A",
    },
    { id: 2, mtype: "21 km", mFees: "599" },
    {
      id: 3,
      mtype: "10 km",
      mFees: "599",
    },
    {
      id: 4,
      mtype: "5 km",
      mFees: "599",
    },
    {
      id: 5,
      mtype: "4 km",
      mFees: "499",
    },
  ],

  // bairganiya mahostav

  EventCard: [
    {
      id: 1,
      eventType: 'Photography Awards 2024',
      price: "1"
    },
    {
      id: 2,
      eventType: 'Painting Talent Hunt 2024',
      price: "1"
    },
    {
      id: 3,
      eventType: 'Fashion Talent Hunt 2024',
      price: "1"
    },
    {
      id: 4,
      eventType: 'Food And Beverage Show',
      price: "1"
    },
    {
      id: 5,
      eventType: 'Dance Talent Hunt 2024',
      price: "1"
    },
  ],

  Programs: [
    {
      id: 1,
      label: "Photography",
      value: 'Photography',
      price: "1"
    },
    {
      id: 2,
      label: "Painting",
      value: 'Painting',
      price: "1"
    },
    {
      id: 3,
      label: "Fashion",
      value: 'Fashion',
      price: "1"
    },
    {
      id: 4,
      label: "Food and Beverage",
      value: 'Food and Beverage',
      price: "1"
    },
    {
      id: 5,
      label: "Dance",
      value: 'Dance',
      price: "1"
    },
  ],

  schoolList: [
    { id: 1, name: "JAUHARIMAL HIGH SCHOOL BAIRGANIA, SITAMARHI" },
    { id: 2, name: "M.N ROY INTER COLLEGE, BARGANIYA, SITAMARHI" },
    { id: 3, name: "PT.D.D.U.M.COLLEGE, BAIRGANIA, SITAMARHI" },
    { id: 4, name: "R.B.P.INTER COLLEGE, BAIRGANIA, SITAMARHI" },
    { id: 5, name: "+2 ADARSH H/S BACHOPATTI, NARHA, SITAMARHI" },
    { id: 6, name: "+2 SRI RAGHUNATH PRASAD NOPANI H/S BAJPATTI, SITAM" },
    { id: 7, name: "NAV UTTKRAMIT H S BHANTARBARI CHORAUT, SITAMARHI" },
    { id: 8, name: "NAV UTTKRAMIT H S RAJWARA SONBARSA, SITAMARHI" },
    { id: 9, name: "NAV UTTKRAMIT H S SUTIHARA PARIHAR, SITAMARHI" },
    { id: 10, name: "PROJECT GIRL’S HIGH SCHOOL BAIRGANIA" },
    { id: 11, name: "T.M.J.M. PROJECT BALIKA H/S, SHIVAIPATTI, SITAMARHI" },
    { id: 12, name: "U M S BHITTA BAZAR, SRIKHANDI BHITTA SURSAND, SITAMARHI" },
    { id: 13, name: "U M S DIHITHI, SHAPUR SITALPATTI BATHANAHA, SITAMARHI" },
    { id: 14, name: "UCHCHA MADHYAMIK VIDYALAY AKHTA WEST" },
    { id: 15, name: "UCHCHA MADHYAMIK VIDYALAY ANHARI" },
    { id: 16, name: "UCHCHA MADHYAMIK VIDYALAY AWAPUR SOUTH" },
    { id: 17, name: "UCHCHA MADHYAMIK VIDYALAY BABURWAN, PARIHAR" },
    { id: 18, name: "UCHCHA MADHYAMIK VIDYALAY BACHHARPUR" },
    { id: 19, name: "UCHCHA MADHYAMIK VIDYALAY BAGHARI" },
    { id: 20, name: "UCHCHA MADHYAMIK VIDYALAY BAGHARI" },
    { id: 21, name: "UCHCHA MADHYAMIK VIDYALAY BAJITPUR BHAUR" },
    { id: 22, name: "UCHCHA MADHYAMIK VIDYALAY BAJPATTI" },
    { id: 23, name: "UCHCHA MADHYAMIK VIDYALAY BAKHARI" },
    { id: 24, name: "UCHCHA MADHYAMIK VIDYALAY BALHA MADHUSUDAN" },
    { id: 25, name: "UCHCHA MADHYAMIK VIDYALAY BANGAON SOUTH" },
    { id: 26, name: "UCHCHA MADHYAMIK VIDYALAY BARAHI" },
    { id: 27, name: "UCHCHA MADHYAMIK VIDYALAY BARARI PHULWARIYA" },
    { id: 28, name: "UCHCHA MADHYAMIK VIDYALAY BARHARWA" },
    { id: 29, name: "UCHCHA MADHYAMIK VIDYALAY BASBITTA" },
    { id: 30, name: "UCHCHA MADHYAMIK VIDYALAY BATHNAHA EAST" },
    { id: 31, name: "UCHCHA MADHYAMIK VIDYALAY BAURA BAJITPUR" },
    { id: 32, name: "UCHCHA MADHYAMIK VIDYALAY BELGANJ" },
    { id: 33, name: "UCHCHA MADHYAMIK VIDYALAY BHAGWANPUR" },
    { id: 34, name: "UCHCHA MADHYAMIK VIDYALAY BHALUAHA" },
    { id: 35, name: "UCHCHA MADHYAMIK VIDYALAY BHASAR MACHHAHA SOUTH" },
    { id: 36, name: "UCHCHA MADHYAMIK VIDYALAY BHAWDEPUR" },
    { id: 37, name: "UCHCHA MADHYAMIK VIDYALAY BHERRAHIYA" },
    { id: 38, name: "UCHCHA MADHYAMIK VIDYALAY BHITHA DHARMPUR" },
    { id: 39, name: "UCHCHA MADHYAMIK VIDYALAY BHUPBHAIRO" },
    { id: 40, name: "UCHCHA MADHYAMIK VIDYALAY BIRAR URDU" },
    { id: 41, name: "UCHCHA MADHYAMIK VIDYALAY BOKHRA" },
    { id: 42, name: "UCHCHA MADHYAMIK VIDYALAY CHAK RAJOPATTI" },
    { id: 43, name: "UCHCHA MADHYAMIK VIDYALAY CHORAUT EAST" },
    { id: 44, name: "UCHCHA MADHYAMIK VIDYALAY CHORAUT WEST" },
    { id: 45, name: "UCHCHA MADHYAMIK VIDYALAY DADHAWARI" },
    { id: 46, name: "UCHCHA MADHYAMIK VIDYALAY DAYAN CHHAPRA" },
    { id: 47, name: "UCHCHA MADHYAMIK VIDYALAY DHANUSHI" },
    { id: 48, name: "UCHCHA MADHYAMIK VIDYALAY DHARHARWA" },
    { id: 49, name: "UCHCHA MADHYAMIK VIDYALAY GANGTI" },
    { id: 50, name: "UCHCHA MADHYAMIK VIDYALAY GARHA" },
    { id: 51, name: "UCHCHA MADHYAMIK VIDYALAY GAURI" },
    { id: 52, name: "UCHCHA MADHYAMIK VIDYALAY GIDDHA FULWARIYA" },
    { id: 53, name: "UCHCHA MADHYAMIK VIDYALAY GURUDAH URF GAUSNAGAR" },
    { id: 54, name: "UCHCHA MADHYAMIK VIDYALAY HARNAHIYA, BATHNAHA" },
    { id: 55, name: "UCHCHA MADHYAMIK VIDYALAY HARPUR PIPRA SUPPI" },
    { id: 56, name: "UCHCHA MADHYAMIK VIDYALAY JAMUA" },
    { id: 57, name: "UCHCHA MADHYAMIK VIDYALAY JHAJHIHAT, PUPRI" },
    { id: 58, name: "UCHCHA MADHYAMIK VIDYALAY KAMALDAH" },
    { id: 59, name: "UCHCHA MADHYAMIK VIDYALAY KANHWAN" },
    { id: 60, name: "UCHCHA MADHYAMIK VIDYALAY KANSAR" },
    { id: 61, name: "UCHCHA MADHYAMIK VIDYALAY KARARWANA, SURSAND" },
    { id: 62, name: "UCHCHA MADHYAMIK VIDYALAY KOTHIYA RAY" },
    { id: 63, name: "UCHCHA MADHYAMIK VIDYALAY KUMHRA VISHNUPUR, DUMRA" },
    { id: 64, name: "UCHCHA MADHYAMIK VIDYALAY KURHAR" },
    { id: 65, name: "UCHCHA MADHYAMIK VIDYALAY KUSHMARI" },
    { id: 66, name: "UCHCHA MADHYAMIK VIDYALAY LAGMA" },
    { id: 67, name: "UCHCHA MADHYAMIK VIDYALAY LAHURIYA" },
    { id: 68, name: "UCHCHA MADHYAMIK VIDYALAY LOHASI" },
    { id: 69, name: "UCHCHA MADHYAMIK VIDYALAY MADHESARA" },
    { id: 70, name: "UCHCHA MADHYAMIK VIDYALAY MADHIYA" },
    { id: 71, name: "UCHCHA MADHYAMIK VIDYALAY MADHOPUR" },
    { id: 72, name: "UCHCHA MADHYAMIK VIDYALAY MADHOPUR RAUSHAN" },
    { id: 73, name: "UCHCHA MADHYAMIK VIDYALAY MADHUBAN" },
    { id: 74, name: "UCHCHA MADHYAMIK VIDYALAY MADHUBAN BASAH PURVI" },
    { id: 75, name: "UCHCHA MADHYAMIK VIDYALAY MADHURAPUR" },
    { id: 76, name: "UCHCHA MADHYAMIK VIDYALAY MAHINDWARA" },
    { id: 77, name: "UCHCHA MADHYAMIK VIDYALAY MAJHAUR" },
    { id: 78, name: "UCHCHA MADHYAMIK VIDYALAY MAJORGANJ" },
    { id: 79, name: "UCHCHA MADHYAMIK VIDYALAY MALAHI" },
    { id: 80, name: "UCHCHA MADHYAMIK VIDYALAY MANIK CHOWK WEST, RUNNISAIDPUR" },
    { id: 81, name: "UCHCHA MADHYAMIK VIDYALAY MANIKPUR MUSHHARNIA" },
    { id: 82, name: "UCHCHA MADHYAMIK VIDYALAY MANIYARI" },
    { id: 83, name: "UCHCHA MADHYAMIK VIDYALAY MARUKI" },
    { id: 84, name: "UCHCHA MADHYAMIK VIDYALAY MATIYARKALA" },
    { id: 85, name: "UCHCHA MADHYAMIK VIDYALAY MEHSAUL GOT" },
    { id: 86, name: "UCHCHA MADHYAMIK VIDYALAY MEHSAUL WEST" },
    { id: 87, name: "UCHCHA MADHYAMIK VIDYALAY MISRAULIYA" },
    { id: 88, name: "UCHCHA MADHYAMIK VIDYALAY MOHINI MANDAL" },
    { id: 89, name: "UCHCHA MADHYAMIK VIDYALAY MURADPUR" },
    { id: 90, name: "UCHCHA MADHYAMIK VIDYALAY MUSACHAK" },
    { id: 91, name: "UCHCHA MADHYAMIK VIDYALAY NANDWARA" },
    { id: 92, name: "UCHCHA MADHYAMIK VIDYALAY NANPUR NORTH" },
    { id: 93, name: "UCHCHA MADHYAMIK VIDYALAY NARANGA SOUTH" },
    { id: 94, name: "UCHCHA MADHYAMIK VIDYALAY NARHA" },
    { id: 95, name: "UCHCHA MADHYAMIK VIDYALAY NARHA, SUPPI" },
    { id: 96, name: "UCHCHA MADHYAMIK VIDYALAY PANDAUL BUZURG" },
    { id: 97, name: "UCHCHA MADHYAMIK VIDYALAY PANDAUL URF PANTHPAKAR" },
    { id: 98, name: "UCHCHA MADHYAMIK VIDYALAY PARIGAMA" },
    { id: 99, name: "UCHCHA MADHYAMIK VIDYALAY PARIHAR SOUTH" },
    { id: 100, name: "UCHCHA MADHYAMIK VIDYALAY PAROHA" },
    { id: 101, name: "UCHCHA MADHYAMIK VIDYALAY PARSA" },
    { id: 102, name: "UCHCHA MADHYAMIK VIDYALAY PARSANDI" },
    { id: 103, name: "UCHCHA MADHYAMIK VIDYALAY PARSAUNI" },
    { id: 104, name: "UCHCHA MADHYAMIK VIDYALAY PARSAUNI KHIRODHAR" },
    { id: 105, name: "UCHCHA MADHYAMIK VIDYALAY PARSHURAMPUR" },
    { id: 106, name: "UCHCHA MADHYAMIK VIDYALAY PATAHI" },
    { id: 107, name: "UCHCHA MADHYAMIK VIDYALAY PATHANPURA" },
    { id: 108, name: "UCHCHA MADHYAMIK VIDYALAY PIPRA BISHANPUR" },
    { id: 109, name: "UCHCHA MADHYAMIK VIDYALAY PIPRADHI" },
    { id: 110, name: "UCHCHA MADHYAMIK VIDYALAY POKHRAIRA" },
    { id: 111, name: "UCHCHA MADHYAMIK VIDYALAY PREMNAGAR" },
    { id: 112, name: "UCHCHA MADHYAMIK VIDYALAY PUNAURA WEST" },
    { id: 113, name: "UCHCHA MADHYAMIK VIDYALAY PUPRI" },
    { id: 114, name: "UCHCHA MADHYAMIK VIDYALAY PURANDAHA RAJWARA WEST" },
    { id: 115, name: "UCHCHA MADHYAMIK VIDYALAY RAIN VISHUNI" },
    { id: 116, name: "UCHCHA MADHYAMIK VIDYALAY RAMNAGAR BEDAUL" },
    { id: 117, name: "UCHCHA MADHYAMIK VIDYALAY RAMPUR PARORI" },
    { id: 118, name: "UCHCHA MADHYAMIK VIDYALAY RANJITPUR EAST" },
    { id: 119, name: "UCHCHA MADHYAMIK VIDYALAY RATANPUR" },
    { id: 120, name: "UCHCHA MADHYAMIK VIDYALAY RIGA-1" },
    { id: 121, name: "UCHCHA MADHYAMIK VIDYALAY RUNNISAIDPUR MADHYA, RUNNISAIDPUR" },
    { id: 122, name: "UCHCHA MADHYAMIK VIDYALAY RUNNISAIDPUR NORTH" },
    { id: 123, name: "UCHCHA MADHYAMIK VIDYALAY RUPAULI" },
    { id: 124, name: "UCHCHA MADHYAMIK VIDYALAY RUPAULI RUPHARA" },
    { id: 125, name: "UCHCHA MADHYAMIK VIDYALAY SINGHACHAURI" },
    { id: 126, name: "UCHCHA MADHYAMIK VIDYALAY SIRAULI-1" },
    { id: 127, name: "UCHCHA MADHYAMIK VIDYALAY SIRKHIRYA" },
    { id: 128, name: "UCHCHA MADHYAMIK VIDYALAY TIKAULI" },
    { id: 129, name: "UCHCHA MADHYAMIK VIDYALAY TURKAULIA" },
    { id: 130, name: "UCHCHA MADHYAMIK VIDYALAY, AMANA" },
    { id: 131, name: "UCHCHA MADHYAMIK VIDYALAY, AZAMGARH" },
    { id: 132, name: "UCHCHA MADHYAMIK VIDYALAY, KUMMA" },
    { id: 133, name: "UCHCHA MADHYAMIK VIDYALAY, SAHIYARA" },
    { id: 134, name: "UPGRADED HIGH SCHOOL ARAJI DILAVAR, SITAMARHI" },
    { id: 135, name: "UPGRADED HIGH SCHOOL BALIGARH, SITAMARHI" },
    { id: 136, name: "UPGRADED HIGH SCHOOL BERWAS, SITAMARHI" },
    { id: 137, name: "UPGRADED HIGH SCHOOL BUDHNAGRA" },
    { id: 138, name: "UPGRADED HIGH SCHOOL DHANUSHI, SITAMARHI" },
    { id: 139, name: "UPGRADED HIGH SCHOOL DHARWARA" },
    { id: 140, name: "UPGRADED HIGH SCHOOL HARI CHHAPRA, DUMRA, SITAMARHI" },
    { id: 141, name: "UPGRADED HIGH SCHOOL KAURIYA LALPUR" },
    { id: 142, name: "UPGRADED HIGH SCHOOL KHOPA, SITAMARHI" },
    { id: 143, name: "UPGRADED HIGH SCHOOL LOHKHAR, SITAMARHI" },
    { id: 144, name: "UPGRADED HIGH SCHOOL MAHUAVA, SITAMARHI" },
    { id: 145, name: "UPGRADED HIGH SCHOOL MANIYARI, DUMRA, SITAMARHI" },
    { id: 146, name: "UPGRADED HIGH SCHOOL NANPUR SOUTH, SITAMARHI" },
    { id: 147, name: "UPGRADED HIGH SCHOOL RAMPUR GANGAULI, RIGA, SITAMARHI" },
    { id: 148, name: "UPGRADED HIGH SCHOOL RAMPUR PACHASI, PUPRI, SITAMARHI" },
    { id: 149, name: "UPGRADED HIGH SCHOOL SONA KHAN, SUPPI, SITAMARHI" },
    { id: 150, name: "UPGRADED HIGH SCHOOL VISHANPUR AADHAR, SITAMARHI" },
    { id: 151, name: "UPGRADED HIGH SCHOOL YADUPATTI SIMRI, SITAMARHI" },
    { id: 152, name: "UPGRADED SR. SECONDARY SCHOOL HARRIPATHANPURA, SITAMARHI" },
    { id: 153, name: "UTKARMIT UCHCH VIDYALYA BELHIYA, BAJPATTI SITAMARHI" },
    { id: 154, name: "UTKARMIT UCHCH VIDYALYA DHANKAUL BUJURG, BAJPATTI, SITAMARHI" },
    { id: 155, name: "UTKARMIT UCHCH VIDYALYA NARANGA, PARIHAR, SITAMARHI" },
    { id: 156, name: "UTKRAMIT HIGH SCHOOL, MAJAULIYA BAZAR, PARIHAR, SITAMARHI" },
    { id: 157, name: "UTKRAMIT HIGH SCHOOL,BASAHA (BALAK), BAJPATTI, SITAMARHI" },
    { id: 158, name: "UTKRAMIT M S AKTHA, PURWI, SUPPI, SITAMARHI" },
    { id: 159, name: "UTKRAMIT M S BAGAHI RUNNI SAIDPUR, SITAMARHI" },
    { id: 160, name: "UTKRAMIT M S BALUA, RUNNI SAIDPUR, SITAMARHI" },
    { id: 161, name: "UTKRAMIT M S BATARA, GAOURA, NANPUR, SITAMARHI" },
    { id: 162, name: "UTKRAMIT M S BELAHI JAIRAM, MAUDHA, BATHANAHA, SITAMARHI" },
    { id: 163, name: "UTKRAMIT M S CHAKAUTI, BOKHARA, SITAMARHI" },
    { id: 164, name: "UTKRAMIT M S DAMAMI MATH BELSAND, SITAMARHI" },
    { id: 165, name: "UTKRAMIT M S DHANUSI, BHIRWARA ILIYAS GARHA, SITAMARHI" },
    { id: 166, name: "UTKRAMIT M S GISARA, PARSAUNI, SITAMARHI" },
    { id: 167, name: "UTKRAMIT M S ITHRAWA, KHAP KHOPARAHA, SONBARSA, SITAMARHI" },
    { id: 168, name: "UTKRAMIT M S MAHESHA-1 RUNNI SAIDPUR, SITAMARHI" },
    { id: 169, name: "UTKRAMIT M S MARPA KACHOR SONBARSA, SITAMARHI" },
    { id: 170, name: "UTKRAMIT M S MAULANAGAR, AWAPUR, PUPURI, SITAMARHI" },
    { id: 171, name: "UTKRAMIT M S PACHANAUR, BELSAND, SITAMARHI" },
    { id: 172, name: "UTKRAMIT M S PAKRI SANSKRIT, BHASAR MACHHAN, SITAMARHI" },
    { id: 173, name: "UTKRAMIT M S RAMNAGAR, SIRAULI-II, RIGA, SITAMARHI" },
    { id: 174, name: "UTKRAMIT M S SIRSIYA BAZAR, SIRSIYA, PARIHAR, SITAMARHI" },
    { id: 175, name: "UTKRAMIT M S THUMMAH, RUNNISAIDPUR, SITAMARHI" },
    { id: 176, name: "UTKRAMIT M S YAMUNA BARAHI BATHNAHA, SITAMARHI" },
    { id: 177, name: "UTKRAMIT M S YOGAVANA, RIGA, SITAMARHI" },
    { id: 178, name: "UTKRAMIT M SCH DOGHARA SAHWAJPUR RIGA, SITAMARHI" },
    { id: 179, name: "UTKRAMIT MADHYAMIK SCHOOL, BAKHARI SURSAND, SITAMARHI" },
    { id: 180, name: "UTKRAMIT MADHYAMIK SCHOOL, BANAUL, SITAMARHI" },
    { id: 181, name: "UTKRAMIT MADHYAMIK SCHOOL, BASEPUR BAJPATTI, SITAMARHI" },
    { id: 182, name: "UTKRAMIT MADHYAMIK SCHOOL, BULAKIPUR RIGA, SITAMARHI" },
    { id: 183, name: "UTKRAMIT MADHYAMIK SCHOOL, HARIBELA, SITAMARHI" },
    { id: 184, name: "UTKRAMIT MADHYAMIK SCHOOL, INDARWA-1, SITAMARHI" },
    { id: 185, name: "UTKRAMIT MADHYAMIK SCHOOL, MADHKAUL BELSAND, SITAMARHI" },
    { id: 186, name: "UTKRAMIT MADHYAMIK SCHOOL, MAHISAUTHA, SITAMARHI" },
    { id: 187, name: "UTKRAMIT MADHYAMIK SCHOOL, POSUA RIGA, SITAMADHI" },
    { id: 188, name: "UTKRAMIT MADHYAMIK SCHOOL, RANAULI, SITAMARHI" },
    { id: 189, name: "UTKRAMIT MADHYAMIK SCHOOL, TARA DEVI RANJITPUR, DUMRA, SITAMARHI" },
    { id: 190, name: "UTKRAMIT MADHYAMIK SCHOOL, USHARHIA, RIGA, SITAMARHI" },
    { id: 191, name: "UTKRAMIT MADHYAMIK VIDYALAY KOYLI BALAK, NANPUR" },
    { id: 192, name: "UTKRAMIT UCHCHA MADHYAMIK VIDYALAY, PUNAURA DUMRA SITAMARHI" },
    { id: 193, name: "UTKRAMIT UCHH SCHOOL, BATH ASLI, NANPUR, SITAMARHI" },
    { id: 194, name: "UTKRAMIT UCHH SCHOOL, CHAKSHAMBHU, RUNNISAIDPUR, SITAMARHI" },
    { id: 195, name: "UTKRAMIT UCHH SCHOOL, HARIHARPUR, PUPRI, SITAMARHI" },
    { id: 196, name: "UTKRAMIT UCHH SCHOOL, MEHSAUL, RUNNISAIDPUR, SITAMARHI" },
    { id: 197, name: "UTKRAMIT UCHH SCHOOL, OLIPUR, RUNNISAIDPUR, SITAMARHI" },
    { id: 198, name: "UTKRAMIT UCHH SCHOOL, VIA MAJAULIYA, PARIHAR, SITAMARHI" },
    { id: 199, name: "UTKRAMIT MADHYAMIK SCHOOL GHAGHARA BAZAR" },
    { id: 200, name: "UTKRAMIT MADHYAMIK SCHOOL, BISHANPUR, SITAMARHI" },
    { id: 201, name: "+2 HIGH SCHOOL BATHNAHA, SITAMARHI" },
    { id: 202, name: "+2 HIGH SCHOOL YOGIBANA BAZAR, SITAMARHI" },
    { id: 203, name: "PROJECT BALIKA HIGH SCHOOL, MAJHOLIA ESTATE, SITAMARHI" },
    { id: 204, name: "SRI RAM PRASAD HIGH SCHOOL PURNAHIA, SITAMARHI" },
    { id: 205, name: "DR. BHIMRAO AMBEDKAR 10+2 ANUSUCHIT JATI BALAK AWASIYA VIDYALAY, RAGHOPUR, BAKHRI, SITAMADHI" },
    { id: 206, name: "H.N HIGH SCHOOL CHANDAULI, SITAMARHI" },
    { id: 207, name: "SRI GURU SHARAN HIGH SCHOOL BELSND" },
    { id: 208, name: "SRI L.S.M. PROJECT BALIKA H/S, BELSAND, SITAMARHI" },
    { id: 209, name: "UPGRADED HIGH SCHOOL BAHURAR NANPUR SITAMARHI" },
    { id: 210, name: "UPGRADED HIGH SCHOOL DORPUR NANPUR SITAMARHI" },
    { id: 211, name: "UPGRADED HIGH SCHOOL MANPUR RATNAWALI SITAMARHI" },
    { id: 212, name: "UPGRADED HIGH SCHOOL MAHUAWA SITAMARHI" },
    { id: 213, name: "UTKRAMIT M S HARPUR BHALHA BATHNAHA SITAMARHI" },
    { id: 214, name: "UTKRAMIT MADHYAMIK SCHOOL DHANGAR PARSAUNI SITAMARHI" },
    { id: 215, name: "UTKRAMIT MADHYAMIK SCHOOL DUMRA BELSAND SITAMARHI" },
    { id: 216, name: "UTKRAMIT MADHYAMIK SCHOOL KORYAHI SURSAND SITAMARHI" },
    { id: 217, name: "UTKRAMIT UCHH SCHOOL BAJITPUR BAJPATTI SITAMARHI" },
    { id: 218, name: "UTKRAMIT UCHH SCHOOL HUMAYUNPUR BAJPATTI SITAMARHI" },
    { id: 219, name: "UTKRAMIT UCHH SCHOOL SIRSI NANPUR SITAMARHI" },
    { id: 220, name: "UTKRAMIT UCHH VIDYALAY PACHRA NIMAHI SITAMARHI" },
    { id: 221, name: "UTKRAMIT M S BASAHIYA HINDI SONBARSA SITAMARHI" },
    { id: 222, name: "K.D.K.N +2 HIGH SCHOOL KHARKA, SITAMARHI" },
    { id: 223, name: "HIGH SCHOOL BARRI BEHTA, SITAMARHI" },
    { id: 224, name: "RAJKIYAKRIT SHRI L.N.S. S. S. SCHOOL, CHOUROUT, SITAMARHI" },
    { id: 225, name: "+2 BISHESHWAR RAMESHWAR SHAHI GIRLS HIGH SCHOOL SURSAND, SITAMARHI" },
    { id: 226, name: "+2 HIGH SCHOOL MANESHWAR STHAN, SITAMARHI" },
    { id: 227, name: "+2 KAMAL DAS GIRLS HIGH SCHOOL SURSAND, SITAMARHI" },
    { id: 228, name: "+2 M.S.S.DAS SMARAK PROJECT BALIKA HIGH SCHOOL, SITAMARHI" },
    { id: 229, name: "DR. I.S.N.R.K.P.L.D. KISAN COLLEGE, BARIYARPUR, SITAMARHI" },
    { id: 230, name: "HIGH SCHOOL ATHRI, SITAMARHI" },
    { id: 231, name: "HIGH SCHOOL BAIRGANIA, SITAMARHI" },
    { id: 232, name: "HIGH SCHOOL BARIYARPUR, SITAMARHI" },
    { id: 233, name: "HIGH SCHOOL DUMRI, SITAMARHI" },
    { id: 234, name: "HIGH SCHOOL MAJARGANJ, SITAMARHI" },
    { id: 235, name: "HIGH SCHOOL PARRI, SITAMARHI" },
    { id: 236, name: "HIGH SCHOOL RAIPUR, SITAMARHI" },
    { id: 237, name: "HIGH SCHOOL VISHNUPUR BASUDEV, SITAMARHI" },
    { id: 238, name: "JAGESHWAR HIGH SCHOOL BHUTHI, SITAMARHI" },
    { id: 239, name: "JANTA HIGH SCHOOL PARSAUNI, SITAMARHI" },
    { id: 240, name: "KAMLA BALIKA HIGH SCHOOL DUMRA, SITAMARHI" },
    { id: 241, name: "MAHANTH RAGHUNATH DAS BALIKA HIGH SCHOOL, SITAMARHI" },
    { id: 242, name: "MAKHNU HIGH SCHOOL TILAK TAJPUR, SITAMARHI" },
    { id: 243, name: "R.R.M.Y.INTER COLLEGE, SITAMARHI" },
    { id: 244, name: "S S T HIGH SCHOOL BALHA MANORATH, SITAMARHI" },
    { id: 245, name: "SHREE LAKSHMI SR. SEC. SCHOOL, SITAMARHI" },
    { id: 246, name: "SHREE MATHURA HIGH SCHOOL, SITAMARHI" },
    { id: 247, name: "SITAMARHI INTER +2 COLLEGE, DUMRA, SITAMARHI" },
    { id: 248, name: "SRI R.D.B.B.C. PROJECT BALIKA HIGH SCHOOL, B. SITAMARHI" },
    { id: 249, name: "T.Y.K.S. COLLEGE, SITAMARHI" },
    { id: 250, name: "S.S +2 HIGH SCHOOL MARPA SIRPAL BASBITA MAJORGANJ, SITAMARHI" },
    { id: 251, name: "HIGH SCHOOL BELSAHANTI KUTIR, SITAMARHI" },
    { id: 252, name: "HIGH SCHOOL JANIPUR, SITAMARHI" },
    { id: 253, name: "PROJECT PT. SHRI N. T. S. BALIKA HIGH SCHOOL, JAHIDPUR, SITAMARHI" },
    { id: 254, name: "H/S KHAIRBA VISHNUPUR, SITAMARHI" },
    { id: 255, name: "S K M +2 HIGH SCHOOL BHISWA BAZAR SITAMARHI" },
    { id: 256, name: "S.C. PROJECT BALIKA HIGH SCHOOL, GORHARI, BELA, M.PAK, SITAMARHI" },
    { id: 257, name: "SRI GANDHI HIGH SCHOOL PARIHAR, SITAMARHI" },
    { id: 258, name: "SRI SONFI BWALI +2 HIGH SCHOOL KOERIYA PIPARA" },
    { id: 259, name: "+2 LM HIGH SCHOOL PUPRI, SITAMARHI" },
    { id: 260, name: "G. S. BAJRANG JALAN PROJECT BALIKA HIGH SCHOOL SITAMARHI" },
    { id: 261, name: "H.R.R.P. MAHILA MAHAVIDHALAY JANAKPUR ROAD, SITAMADHI" },
    { id: 262, name: "UCHCHA MADHYAMIK VIDYALAY BAGAHA SONBARSA SITAMARHI" },
    { id: 263, name: "UCHCHA MADHYAMIK VIDYALAY BAHERA" },
    { id: 264, name: "UCHCHA MADHYAMIK VIDYALAY BETHA" },
    { id: 265, name: "UCHCHA MADHYAMIK VIDYALAY DHANHA" },
    { id: 266, name: "UCHCHA MADHYAMIK VIDYALAY KATHAUR PARSAUNI SITAMARHI" },
    { id: 267, name: "UCHCHA MADHYAMIK VIDYALAY KHARKA BASANT NORTH" },
    { id: 268, name: "UCHCHA MADHYAMIK VIDYALAY KUARI MADAN" },
    { id: 269, name: "UCHCHA MADHYAMIK VIDYALAY MADARIPUR" },
    { id: 270, name: "UCHCHA MADHYAMIK VIDYALAY MEHSAUL EAST" },
    { id: 271, name: "UCHCHA MADHYAMIK VIDYALAY MOHNI" },
    { id: 272, name: "UCHCHA MADHYAMIK VIDYALAY PACHTAKI YADU" },
    { id: 273, name: "UCHCHA MADHYAMIK VIDYALAY RASALPUR" },
    { id: 274, name: "+2 T.R HIGH SCHOOL SAHBAJPUR, KHARSAN RIGA, SITAMARHI" },
    { id: 275, name: "HIGH SCHOOL BABHANGAMA SITAMARHI" },
    { id: 276, name: "M.S.A.R +2 HIGH SCHOOL REWASI RIGA, SITAMARHI" },
    { id: 277, name: "R. JHA COLLEGE, SITAMARHI" },
    { id: 278, name: "ADARSH HIGH SCHOOL MAGHAUL, SITAMARHI" },
    { id: 279, name: "M. K.BALIKA S.S. SC. MANIK CHOWK, SITAMARHI" },
    { id: 280, name: "M.V. GIRI HIGH SCHOOL MANIK CHOWK, SITAMARHI" },
    { id: 281, name: "R.D. HIGH SCHOOL MORSAND, SITAMARHI" },
    { id: 282, name: "R.N.T.S. COLLEGE, MORSAND, SITAMARHI" },
    { id: 283, name: "+2 S.L.D.F HIGH SCHOOL JAYNAGAR, SITAMARHI" },
    { id: 284, name: "HIGH SCHOOL SINGHBAHINI, SITAMARHI" },
    { id: 285, name: "M.K.COLLEGE, BHUTAHI, SITAMARHI" },
    { id: 286, name: "RAMGULAM HIGH SCHOOL KANHAULI BHANDSAR, SITAMARHI" },
    { id: 287, name: "S.Y.YADAV INTER COLLEGE, LALBANDI, SITAMARHI" },
    { id: 288, name: "SRI NANDIPAT JITU +2 ANTARBARTI COLLEGE, SONBARSA" },
    { id: 289, name: "UCHCHA MADHYAMIK VIDYALAY GHURGHURA HANUMAN NAGAR" },
    { id: 290, name: "UCHCHA MADHYAMIK VIDYALAY KHARKA" },
    { id: 291, name: "UCHCHA MADHYAMIK VIDYALAY MANPAUR" },
    { id: 292, name: "UCHCHA MADHYAMIK VIDYALAY MARPA INDARWA 2" },
    { id: 293, name: "UCHCHA MADHYAMIK VIDYALAY PIPRA PARSAIN" },
    { id: 294, name: "UCHCHA MADHYAMIK VIDYALAY SRIKHANDI BHITHA WEST" },
    { id: 295, name: "+2 SARVAJANIK HIGH SCHOOL, SUPPI, SITAMARHI" },
    { id: 296, name: "PROJECT INDU BALIKA KANYA HIGH SCHOOL, SITAMARHI" },
    { id: 297, name: "+2 SCHOOL SHRI RAMSWARATH HIGH S.S RADHAUR, SITAMARHI" },
    { id: 298, name: "RAJKIYAKRIT +2 SARYU HIGH SCHOOL, SURSAND, SITAMARHI" },
    { id: 299, name: "Dayaramka International Public School, Avkari Chowk, Bairgania" },
    { id: 300, name: "Imperial Academy, Shivalaya Mandir Road, Bairgania" },
    { id: 301, name: "Chanakya Academy, Shivalya Mandir Rd, Patel Nagar, Bairgania" },
    { id: 302, name: "J S Boarding School, Ward No-01, Police Station Road, Near Baudhi Mata Mandir, Bairgania" },
    { id: 303, name: "Adarsh Vidya Mandir School, Bairgania" },
    { id: 304, name: "B.T Children Public School, Bairgania" },
    { id: 305, name: "Brilliant Public School, Parsauni, Bairgania" },
    { id: 306, name: "Priya Rani Roy Degree College, Bairgania" },
    { id: 307, name: "RBP Inter College, Bairgania" },
    { id: 308, name: "Pandit Dindayal Upadhyaya Memorial College, Sinduriya Chowk, Bairgania" },
    { id: 309, name: "Sitamarhi District Athletics Association, Ward No.9, Kailshpuri, Dumra, Sitamarhi" },
    { id: 310, name: "Saraswati Vidya Mandir, Ring Bandh, Bhawadepur, Sitamarhi" },
    { id: 311, name: "Bharti Public School, Dumra Kothi Campus, Dumra, Sitamarhi" },
    { id: 312, name: "Allen Classes, In Front of Fashion Plus Mall, Bazar Samiti, Bairgania" },
    { id: 313, name: "Shiksha, Ward No-07, Opp. Of Gurukurl Street, Near By Railway Crossing, Bairgania" },
    { id: 314, name: "Target Tutorial Centre, Baba Lal Das Math Road, Bairgania" },
    { id: 315, name: "Magic Mathematics Classes, Ghee Patti Road, Bairgania" },
    { id: 316, name: "Vibrant Institute, Police Station Road, Near By Post Office, Bairgania" },
    { id: 317, name: "Pandey's Mathematics & Commerce, Near Baba Lal Das Math, Bairgania" },
    { id: 318, name: "Rainbow Classes & Junior Rainbow Classes, 1st Floor, Durga Mandir Road, Bajrang Chowk, Asogi, Bairgania" },
    { id: 319, name: "Singh Mathematic Classes, Near Baba Lal Das Math, Bairgania" },
    { id: 320, name: "Arya Science Classes, Near Baba Lal Das Math, Bairgania" },
    { id: 321, name: "Mission Boarding School, Bairgania" },
    { id: 322, name: "Galaxy Point Public School, Ward No-11, Mai Asthan, Bairgania" },
    { id: 323, name: "H. R. Memorial School, Joriahi Bazar, Bairgania" },
    { id: 324, name: "Guru Gyan International School, Belganj, Bairgania" },
    { id: 325, name: "Sunrise Academy, Pachtaki Jaddu, Bairgania" },
    { id: 326, name: "Thakur Prasad Rama Devi Saraswati Shishu Mandir, Ramruch Nagar, Dumarbana, Bairgania" },
    { id: 327, name: "New Carrier Maker Public School, Satpurwa, Patahi, Bairgania" },
    { id: 328, name: "Vivekanand Public School, Ward No-04, Asogi, Bairgania" },
    { id: 329, name: "New Saraswati Shishu Niketan, Bengahi, Bairgania" },
    { id: 330, name: "Delhi Public School, Parsauni, Bairgania" },
    { id: 331, name: "Devika Rani Roy Public School, Bairgania" },
    { id: 332, name: "Eduwave International Boarding School, Bairgania" },
    { id: 333, name: "Eklavya Public School, Bairgania" },
    { id: 334, name: "H.R Memorial School, Bairgania" },
    { id: 335, name: "N R Children Academy, Bairgania" },
    { id: 336, name: "P.S. Niketan, Bairgania" },
    { id: 337, name: "ST. Teresa High School, Bairgania" },
    { id: 338, name: "N E O Public School, Girls School Extension Road, Ward No-11, Indira Nagar, Sitamarhi" },
    { id: 339, name: "Holy Faith Public High School, Mirchai Patti, Ward No-11, Shankar Chowk, Sitamarhi" },
    { id: 340, name: "Mary Mount Preparatory School, Mela Road, Lohiyanagar, Yadav Nagar, Sitamarhi" },
    { id: 341, name: "RS Mother's International School, Raza Marg, Nahar Chauk, Talkhapur, Dumra, Sitamarhi" },
    { id: 342, name: "HELLENS SCHOOL, Amghatta Road, Dumra, Sitamarhi" },
    { id: 343, name: "St. Joseph’s Mission Preparatory School, Lane no. 4, Shanti Nagardumra Road, Shanti Nagar, Sitamarhi" },
    { id: 344, name: "TINY TOTS HIGH SCHOOL, Ward No 25, Near Railway Station, Naya Tola, Mehsoul, Sitamarhi" },
    { id: 345, name: "JANKI VIDYA NIKETAN, Sitanagar Ward no.29, Chak Rajopatti, Sitamarhi" },
    { id: 346, name: "Don Bosco's School, Near Shiv Mandir, Sharif Colony, Chak Rajopatti, Sitamarhi" },
    { id: 347, name: "Al-Hira Public School, Ansari Road Extension, Sharif Colony, Sitamarhi" },
    { id: 348, name: "Sacred Heart School, Anand Nagar, Talkhapur, Dumra, Sitamarhi" },
    { id: 349, name: "Bindal Public School, Chakmahila, Sitamarhi" },
    { id: 350, name: "ST XAVIER'S HIGH SCHOOL, Laxmana Nagar, Chakmahila, Sitamarhi" },
    { id: 351, name: "Arya Preparatory SCHOOL, Nahar Chowk, Basant Vihar, Chak Rajopatti, Sitamarhi" },
    { id: 352, name: "Vidya Mandir an Ideal School, Jail Road, Ward No-06, Dumra, Sitamarhi" },
    { id: 353, name: "Vidya Bharati School, Vidya Gram North of Shanti Nagar Dumra Road, Sitamarhi" },
    { id: 354, name: "Royal Hills International School Junior, Admin Office, RHIS Building, Nahar Road, Near Nahar Chowk, Shanti Nagar, Sitamarhi" },
    { id: 355, name: "St Mary Heart Boarding School, Baswariya, Sitamarhi" },
    { id: 356, name: "R.O.S. Public School, Riga Road, Khairba, Sitamarhi" },
    { id: 357, name: "P D Foundation School, Chakmahila Middle School, Ward No-16, Sitamarhi" },
    { id: 358, name: "M.G.N Public School, Lalit Nagar, Punaura Pashchim, Sitamarhi" },
    { id: 359, name: "Nikhil Shyama DAV Public School, BHISA ROAD, Near Bhisha Halt/STATION, Dumra, Sitamarhi" },
    { id: 360, name: "Buddha Central School, Buddha Colony, Chandia, Riga Road, Near Dwarka Palace, Riga, Sitamarhi" },
    { id: 361, name: "Little Millennium Pre-School, The 98 Angles, Pratap Nagar East, Ward No- 29, Near SSB Camp, Industrial Area, Sitamarhi" },
    { id: 362, name: "Acharya Sudarshan Educational Charitable Trust (Regd.), Sudarsan Nagar, Kharsana Chowk, Riga, Sitamarhi" },
    { id: 363, name: "Vibrant Preparatory School, Srinagar, North of Nahar Chowk Lane, No.4, Dumra Road, Sitamarhi" },
    { id: 364, name: "Frontera Public School, Hospital Road, Near Litchi Bagan SBI ATM, Sitamarhi" },
    { id: 365, name: "UMEGA ITI Sitamarhi, River Valley Colony, Chakmahila, Sitamarhi" },
    { id: 366, name: "Satyam Institute of Science and Technology, Mohanpur, Sitamarhi" },
    { id: 367, name: "Phoolwari the Kids' Home, Ward no 19, Main Road, Mahanth Sah Chowk, Sitamarhi" },
    { id: 368, name: "Golden Bharati Public School, North of Nahar Chowk, Dumra Road, Sitamarhi" },
    { id: 369, name: "The Global Public School, Madhuban, Dumra Road, Sitamarhi" },
    { id: 370, name: "Chinmaya International School, Talkhapur Nahar, Chowk, Dumra Road, Sitamarhi" },
    { id: 371, name: "Sunshine Public School, Shanti Nagar, Sitamarhi" },
    { id: 372, name: "DELHI PUBLIC SCHOOL LAGMA, Nandipat – Lagma, P.O.: Lagma, Dist. Sitamarhi" },
    { id: 373, name: "Sitamarhi High School, Dumra, Sitamarhi" },
    { id: 374, name: "The Child's Future Preparatory School, Naya Tola Riga Road, Sitamarhi" },
    { id: 375, name: "DAV PUBLIC SCHOOL, Ward Number 14, Champanagar, Koat Bazar, Sitamarhi" },
    { id: 376, name: "Jawahar Navodaya Vidyalaya, Khairvi, District Sitamarhi" },
    { id: 377, name: "Open Mind Secondary School, Bathnaha, Bathanaha Pashchimi, Sitamarhi" },
    { id: 378, name: "Gyanchakra Children Public School, Mahavir Nagar Ward NO.-14, Ring Bandh Rd, Near Power House Zim, Sitamarhi" },
    { id: 379, name: "Home Tuition Bureau, Hospital Road & Bata Gali, Sitamarhi" },
    { id: 380, name: "H.M. preparatory School, Ward No-43, Manju Bhawan Jail Road, Dumra, Sitamarhi" },
    { id: 381, name: "Springdales International Girls School, N.H-77, Four Lanes Chowk, Bariyarpur, Sitamarhi" },
    { id: 382, name: "Sitamarhi Public School, Ward No-13, Koat Bazar, Sitamarhi" },
    { id: 383, name: "MAHANTH RAGHUNATH DAS GIRLS HIGH SCHOOL (MRD), Sitamarhi" },
    { id: 384, name: "Gyan Jyoti Public School, Railway Station Road, Bazar Samiti, Sitamarhi" },
    { id: 385, name: "Radha Karishnan Public School, Nahar Chowk, Chak Rajopatti, Sitamarhi" },
    { id: 386, name: "Acharya Sudarshan Vidyapeeth School, Sudarshan Dham, Majorganj, Sitamarhi" },
    { id: 387, name: "Arya Public School, Bathnaha, Bathanaha Pashchimi, Sitamarhi" },
    { id: 388, name: "GREEN RAY'S SCHOOL, Rama Gas Agency Road, Talkhapur, Dumra, Sitamarhi" },
    { id: 389, name: "Shri Laxmi Kishori College-S L K, Sitamarhi Main Road, Laxmana Nagar, Sitamarhi" },
    { id: 390, name: "Rup Bala Memorial ANM Training Centre, Hospital Rd, Vidya Pati Nagar, Sitamarhi" },
    { id: 391, name: "Sun Rise Public School, Madhavnagar, Morsand Road, Runni Saidpur Madhya, Sitamarhi" },
    { id: 392, name: "SITARAM DAV PUBLIC SCHOOL, PUPRI (10+2), Janakpur Road, Pupri, Sitamarhi" },
    { id: 393, name: "Aptech Computer Education, Opposite Karpoori Chhatrawas, Hospital Road, Mohan Pur, Sitamarhi" },
    { id: 394, name: "Sharda Niketan Public School, Ward.No - 11, Old Exchange Road, Mirchai Patti, Sitamarhi" },
    { id: 395, name: "Mahendra's Institute - Sitamarhi for Banking SSC State Exam Railway Teaching and CUET, Janki Mandir Road, Near HAJARINATH, Laxmana Nagar, Sitamarhi" },
    { id: 396, name: "Ram Sewak Singh Mahila College-R.S.S, Near Dumra Hawai Field, Sitamarhi" },
    { id: 397, name: "Himalaya Public School, Ward No. - 6, Belsand" },
    { id: 398, name: "Anand Home Tuition, Dumra Road Srinagar, North of Nahar Chowk, Near Vibrant Preparatory School, Sitamarhi" },
    { id: 399, name: "Vishwas Computer Institute, Ring Bandh Rd, Laxmana Nagar, Sitamarhi" },
    { id: 400, name: "MAHATMA BUDDHA TEACHER TRAINING COLLEGE, Sonbarsa Road, PO- Dhruv Nagar, Panch-Mile, Kamaldah, Sitamarhi" },
    { id: 401, name: "Gyan Varsha International School, Village: Madh Kaul, Taluka: Belsand" },
    { id: 402, name: "ADARSH PREPARATORY SCHOOL, Fore Lane, Bariyarpur, (Near Fish Godam, Dostpur, Khadauli) Sitamarhi" },
    { id: 403, name: "DAV PUBLIC SCHOOL, RUNNISAIDPUR, Runnisaidpur" },
    { id: 404, name: "Eklavya Classes, Kapraul Road, Lohapatti, Sitamarhi" },
    { id: 405, name: "Gyan Bharti Public School, Morsand Road, Runni Saidpur, Sitamarhi" },
    { id: 406, name: "PLUS POINT EDUCATION CENTER, Harsing Pur, Sitamarhi" },
    { id: 407, name: "Modern Global School, Birauli chowk, Bachharpur, Pupri Road" },
    { id: 408, name: "Nutan college of Nursing, Manik Chauk Dakshini, Sitamarhi" },
    { id: 409, name: "ACHARYA DHRUBASHA COLLEGE OF HEALTH EDUCATION, Village - Dhrub Nagar, PO, Panch-Mile, Kamladih, Sitamarhi" },
    { id: 410, name: "Government Polytechnic, Malahi, Sursand Pashchimi, Sitamarhi" },
    { id: 411, name: "Echotech Skills Hub Private Limited, Janki Asthan, Yadav Nagar Ward No.01, Sitamarhi" },
    { id: 412, name: "Shri Nandipat Jitu Uchch Vidyalaya-S N J HIGH SCHOOL, SONBARSA" },
    { id: 413, name: "Don Bosco Tech- Sitamarhi (Community College), Industrial Area Rajopatti, Sitamarhi" },
    { id: 414, name: "ST XAVIER'S HIGH SCHOOL, Behind Laxmi High School Gudri River Road Ward No-16, Sitamarhi" },
    { id: 415, name: "ST XAVIER'S HIGH SCHOOL, Pupri, Janashpur Road, Pupri Bazar, Pupari" },
    { id: 416, name: "ST XAVIER'S HIGH SCHOOL, Cluster, SONBARSA" },
    { id: 417, name: "Arya Public School, Simra near Police line, Sitamarhi" },
    { id: 418, name: "Arya Public School, Bathnaha, Bathanaha Pashchimi, Sitamarhi" },
    { id: 419, name: "HOLY MARY INTERNATIONAL SCHOOL, Bhoop Bhairo, Chandani Chowk, Pupri Road, Sitamarhi" },
    { id: 420, name: "Acharya Sudarshan Public School, Mehsaul Gote, Sitamarhi" },
    { id: 421, name: "H.M.Prepatory School, Ward No-6, Krishna Nivas, Sitamarhi - Ratwara - Nanpur Road, Dumra, Sitamarhi" },
    { id: 422, name: "Gyan Jyoti Boarding School, Punaura Road, Gaushala Chawk, Khairwa Road, Sitamarhi" },
    { id: 423, name: "The Sunrise Public school, Bhaur Garh, Bokhra, PS-Nanpur, Sitamarhi" },
    { id: 424, name: "The Sunrise Public school, Bhaur Garh, Bokhra, PS-Nanpur, Sitamarhi" },
    { id: 425, name: "other", is_other: true },
  ],

  // cricket Leage
  trouserSize: [
    { id: 1, value: "26", label: "26 (S)" },
    { id: 2, value: "28", label: "28 (M)" },
    { id: 3, value: "30", label: "30 (L)" },
    { id: 4, value: "32", label: "32 (XL)" },
    { id: 5, value: "34", label: "34 (XXL)" },
  ],

  // state

  indianState: [
    { "id": 1, "state": "Andhra Pradesh" },
    { "id": 2, "state": "Arunachal Pradesh" },
    { "id": 3, "state": "Assam" },
    { "id": 4, "state": "Bihar" },
    { "id": 5, "state": "Chhattisgarh" },
    { "id": 6, "state": "Goa" },
    { "id": 7, "state": "Gujarat" },
    { "id": 8, "state": "Haryana" },
    { "id": 9, "state": "Himachal Pradesh" },
    { "id": 10, "state": "Jharkhand" },
    { "id": 11, "state": "Karnataka" },
    { "id": 12, "state": "Kerala" },
    { "id": 13, "state": "Madhya Pradesh" },
    { "id": 14, "state": "Maharashtra" },
    { "id": 15, "state": "Manipur" },
    { "id": 16, "state": "Meghalaya" },
    { "id": 17, "state": "Mizoram" },
    { "id": 18, "state": "Nagaland" },
    { "id": 19, "state": "Odisha" },
    { "id": 20, "state": "Punjab" },
    { "id": 21, "state": "Rajasthan" },
    { "id": 22, "state": "Sikkim" },
    { "id": 23, "state": "Tamil Nadu" },
    { "id": 24, "state": "Telangana" },
    { "id": 25, "state": "Tripura" },
    { "id": 26, "state": "Uttar Pradesh" },
    { "id": 27, "state": "Uttarakhand" },
    { "id": 28, "state": "West Bengal" },
    { "id": 29, "state": "Andaman and Nicobar Islands" },
    { "id": 30, "state": "Chandigarh" },
    { "id": 31, "state": "Dadra and Nagar Haveli and Daman and Diu" },
    { "id": 32, "state": "Delhi" },
    { "id": 33, "state": "Lakshadweep" },
    { "id": 34, "state": "Puducherry" }
  ]


};

export default ArrayObj;
